import ReconnectingEventSource from 'reconnecting-eventsource'
import axios from 'axios'

export default class StreamingStatusProvider {
  paymentReference
  onStatusUpdateCallback
  latestStatus
  eventSource

  constructor(paymentReference) {
    this.paymentReference = paymentReference
    this._startStream()
  }

  onStatusUpdate(callback) {
    this.onStatusUpdateCallback = callback
    this.latestStatus && callback(this.latestStatus)
  }

  onCompletion(completionCallback) {
    this.completionCallback = completionCallback
    this._handleCompletionUrl()
  }

  stop() {
    if (this.eventSource) {
      this.eventSource.close()
      this.eventSource = undefined
    }
  }

  restart() {
    this.stop()
    this._startStream()
  }

  _startStream() {
    this.eventSource = new ReconnectingEventSource(
      `${process.env.REACT_APP_CPP_OTC_BFF_URL || ''}/api/payments/${this.paymentReference}/status-streaming`,
      {
        withCredentials: false,
        max_retry_time: 1000,
      })

    this.eventSource.onmessage = event => {
      console.log(`payment streaming message: ${JSON.stringify(event.data)}`)
      const statusEvent = JSON.parse(event.data)
      if(!(statusEvent.status && statusEvent.status === 'HEARTBEAT')) {
        this._handleNewStatus(statusEvent)
      }
    }

    this.eventSource.onerror = e => {
      console.log('payment streaming error occurred: ', JSON.stringify(e))
    }
  }

  async _fetchLastStatus() {
    const {data} = await axios.get(`${process.env.REACT_APP_CPP_OTC_BFF_URL || ''}/api/payments/${this.paymentReference}/status`)
      .catch((error) => {
        console.log(`Error ${error} while invoking payment status`)
      })
    return data
  }

  _handleNewStatus(newStatus) {
    this.latestStatus = newStatus

    if (newStatus.final) {
      this._fetchLastStatus()
        .then((lastStatus) => {
          console.log(`last status: ${JSON.stringify(lastStatus)}`)
          if(lastStatus.final && newStatus.paymentStatus === lastStatus.paymentStatus
            && lastStatus.statusUpdateTime === newStatus.statusTimestamp) {
            this.stop()
            this._handleCompletionUrl()
          }
        })
        .catch((error) => {
          console.log(`Error ${error} while fetching last status`)
        })
    }

    this.onStatusUpdateCallback && this.onStatusUpdateCallback({
      paymentStatus: newStatus.paymentStatus,
      components: newStatus.components,
      needsOverride: newStatus.needsOverride,
    })
  }

  _handleCompletionUrl() {
    if (this.latestStatus && this.latestStatus.completionUrl && this.latestStatus.completionUrl.trim().length > 0) {
      this.completionCallback && this.completionCallback(this.latestStatus.completionUrl)
    }
  }
}
