import React, {useState} from 'react'
import OverrideModal from '../../OverrideModal'
import {OverpaymentRefundModes} from './OverpaymentRefundModes'
import * as PropType from 'prop-types'
import PropTypes from 'prop-types'
import {StringUtil} from '../../payment-form/StringUtil'
import axios from 'axios'
import {validateCsrCredentialsApiCall} from '../../OverrideModal'

const CHEQUE_ONLY_REFUND_THRESHOLD = 500
const SINGLE_PARTY = 'SP'
const DEALER = 'DL'

const OverpaymentOverrideModal = ({
  showModal,
  closeModal,
  title,
  invoiceId,
  amountRequested,
  amountTendered,
  approveCallback,
  customerId,
  paymentReference,
  customerReceiptType,
}) => {
  const [refundType, setRefundType] = useState('CHEQUE')

  const saveAdjustmentComponent = async ({
    paymentReference,
    agencyCsrId,
  }) => {
    try {
      const amount = amountTendered - amountRequested
      const type = 'OVERPAYMENT'
      const supervisorCsrId = agencyCsrId
      return await axios.post(
        `${process.env.REACT_APP_CPP_OTC_BFF_URL || ''}/api/payments/adjustment`,
        {
          paymentReference,
          amount,
          type,
          customerId,
          supervisorCsrId,
          refundType,
        }
      )
    } catch (error) {
      console.log(error.response)
      return error.response
    }
  }

  const overpaymentOverride = async (parentOverrideDetails) => {
    const overrideResult = await validateCsrCredentialsApiCall(
      parentOverrideDetails
    )

    if (overrideResult.status === 200) {
      const agencyCsrId = overrideResult.data
      const adjustmentDetails = {
        paymentReference,
        agencyCsrId,
      }
      const adjustmentResult= await saveAdjustmentComponent(adjustmentDetails)

      if (adjustmentResult.status === 200) {
        approveCallback()
      } else {
        return adjustmentResult
      }
    }
    return overrideResult
  }

  const handleApproveClick = (
    parentOverrideDetails,
    handleOverridePaymentApiCall
  ) => {
    const overrideDetails = {
      ...parentOverrideDetails,
      paymentReference,
    }

    handleOverridePaymentApiCall(overrideDetails, overpaymentOverride)
  }
  const refundAmount = amountTendered - amountRequested
  const chequeOnlyRefundType = (refundAmount > CHEQUE_ONLY_REFUND_THRESHOLD)
  const approveEnabled = customerReceiptType === SINGLE_PARTY || customerReceiptType === DEALER
  const errorMsg = 'Cannot process - Overpayment can be accepted only for Single Party or Dealer receipt types.' +
    ' Please cancel and proceed with Card or Cash.'

  return (
    <OverrideModal
      errorMsg={approveEnabled ? '' : errorMsg}
      approveEnabled={approveEnabled}
      showModal={showModal}
      approve={handleApproveClick}
      title={title}
      closeModal={closeModal}
    >
      <div className="grid grid-cols-12 gap-2 max-w-lg">
        <div className="col-span-7 font-bold">Invoice ID</div>
        <div id="invoiceId" className="col-span-5 text-right pb-1">{invoiceId}</div>
        <div className="col-span-7">Amount payable</div>
        <div id="amountRequested" className="col-span-5 text-right">
          {StringUtil.formattedAmount(amountRequested)}
        </div>
        <div className="col-span-7">Cheque/money order amount</div>
        <div id="amountTendered" className="col-span-5 text-right">
          {StringUtil.formattedAmount(amountTendered)}
        </div>
        <div className="col-span-7 font-bold">Overpayment refund amount</div>
        <div id="refundAmount" className="col-span-5 font-bold text-right">
          {StringUtil.formattedAmount(amountTendered - amountRequested)}
        </div>
      </div>
      <div className="flex flex-col pt-6 pb-6">
        <label className="font-bold">Customer ID</label>
        <input id="customer-id" data-testid="customer-id"
               className="p-1 mb-3 border-2 rounded block text-sm w-full border-snsw-gray-4"
               disabled={true}
               value={customerId}/>
        <OverpaymentRefundModes
          customerId={customerId}
          customerReceiptType={customerReceiptType}
          chequeOnlyRefundType={chequeOnlyRefundType}
          refundTypeSelectedCallback={(value) => {
            if (value === 'refund-mode-cheque') {
              setRefundType('CHEQUE')
            } else if (value === 'refund-mode-eft') {
              setRefundType('EFT')
            }
          }}
        />
        <input id="refundsInfo" data-testid="refundsInfo"
               className="p-1 mb-3 mt-3 border-2 rounded block text-sm font-bold text-center w-full border-snsw-gray-4"
               disabled={true}
               value="Refunds are usually processed within 2 business days."/>
      </div>
    </OverrideModal>
  )
}

OverpaymentOverrideModal.propTypes = {
  showModal: PropType.bool.isRequired,
  closeModal: PropType.func.isRequired,
  title: PropType.string.isRequired,
  invoiceId: PropType.string.isRequired,
  amountRequested: PropType.number.isRequired,
  amountTendered: PropType.number.isRequired,
  approveCallback: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
  paymentReference: PropTypes.string.isRequired,
  customerReceiptType: PropType.string,
}

export default OverpaymentOverrideModal
