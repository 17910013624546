import React from 'react'
import PropTypes from 'prop-types'
import {VoidedCashStatusMessage} from '../cash/CashStatusMessage'
import {VoidedChequeStatusMessage} from '../cheque/ChequeStatusMessage'
import {VoidedMoneyOrderStatusMessage} from '../moneyOrder/MoneyOrderStatusMessage'

VoidedStatusMessage.propTypes = {
  component: PropTypes.object,
}

export function VoidedStatusMessage({component}) {
  return (
    <div className='ml-8' key={component}>
      {component.paymentMethod === 'CASH' && (
        <VoidedCashStatusMessage
                    roundedAmount={component.amount}/>
      )}

      {component.paymentMethod === 'CHEQUE' && (
        <VoidedChequeStatusMessage
                    amount={component.amount}
                    chequeNumber={component.chequeNumber}/>
      )}

      {component.paymentMethod === 'MONEY_ORDER' && (
        <VoidedMoneyOrderStatusMessage
                    amount={component.amount}
                    moneyOrderNumber={component.moneyOrderNumber}/>
      )}
    </div>
  )
}


