export const CHANGE_PAYMENT_METHOD = 'CHANGE_PAYMENT_METHOD'

export const SET_CARD_AMOUNT = 'SET_CARD_AMOUNT'

export const SET_CHEQUE_NUMBER = 'SET_CHEQUE_NUMBER'
export const SET_CHEQUE_BSB = 'SET_CHEQUE_BSB'
export const SET_CHEQUE_ACCOUNT = 'SET_CHEQUE_ACCOUNT'
export const SET_CHEQUE_TYPE = 'SET_CHEQUE_TYPE'
export const SET_CHEQUE_AMOUNT = 'SET_CHEQUE_AMOUNT'
export const SET_CHEQUE_UNDERPAYMENT = 'SET_CHEQUE_UNDERPAYMENT'

export const SET_CASH_TENDERED_AMOUNT = 'SET_CASH_TENDERED_AMOUNT'
export const SET_CASH_AMOUNT = 'SET_CASH_AMOUNT'

export const SET_MONEY_ORDER_NUMBER = 'SET_MONEY_ORDER_NUMBER'
export const SET_MONEY_ORDER_ISSUED_DATE = 'SET_MONEY_ORDER_ISSUED_DATE'
export const SET_MONEY_ORDER_AMOUNT = 'SET_MONEY_ORDER_AMOUNT'
export const SET_MONEY_ORDER_UNDERPAYMENT = 'SET_MONEY_ORDER_UNDERPAYMENT'

export const ADD_TO_TOTAL = 'ADD_TO_TOTAL'
export const RESET_PAYMENT_FORM = 'RESET_PAYMENT_FORM'
export const RESET_PAYMENT_PROCESS = 'RESET_PAYMENT_PROCESS'
export const PROCESS_UNDERPAYMENT = 'PROCESS_UNDERPAYMENT'

export const SET_PAYMENT = 'SET_PAYMENT'
