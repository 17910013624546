import React from 'react'
import redCross from '../.././../assets/images/processing-summary/red-cross.svg'
import PropTypes from 'prop-types'

const createDivClassNames = ({topDivClass, subDivClass, inputType, labelClass, inputClass}) => {
  return {
    topDivClass: topDivClass || 'flex flex-col m-8',
    subDivClass: (subDivClass === null || subDivClass === undefined) ? 'flex' : subDivClass,
    inputType: inputType || 'text',
    labelClass: labelClass || 'w-1/2 self-center text-lg',
    inputClass: inputClass || 'w-1/2 text-right p-2 border-2 rounded block text-sm',
  }
}

export function ValidatableTextField(props) {
  const {
    label, inputId, errorMessage, placeholder, initValue, textChangedCallback, validationCallback,
  } = props

  const {
    topDivClass, subDivClass, inputType, labelClass, inputClass,
  } = createDivClassNames(props)
  const borderColor = errorMessage ? 'border-snsw-red-error' : 'border-snsw-gray-4'

  const createChildren = (
    <React.Fragment>
      <label className={labelClass}>{label}</label>
      {((inputType === 'textarea') ?
        <textarea id={inputId}
                    data-testid={inputId}
                    className={`${inputClass} ${borderColor}`}
                    onChange={({target: {value}}) => textChangedCallback(value)}
                    placeholder={placeholder}
                    onBlur={validationCallback}
                    value={initValue}
        /> : <input id={inputId}
                      data-testid={inputId}
                      type={inputType}
                      className={`${inputClass} ${borderColor}`}
                      onChange={({target: {value}}) => textChangedCallback(value)}
                      placeholder={placeholder}
                      onBlur={validationCallback}
                      value={initValue}/>
      )}
    </React.Fragment>)


  return (
    <div className={`${topDivClass}`}>
      {subDivClass ?
        <div className={`${subDivClass}`}>
          {createChildren}
        </div>
        :
        <div>
          {createChildren}
        </div>
      }

      {errorMessage ? (
        <div className='flex'>
          <div className='flex p-2'>
            <img className="w-auto h-4 mt-1 mr-2 " src={redCross} alt='validation error'/>
            <p id={inputId + '-validation-error'} className='text-sm text-red-700 '>
              {errorMessage}
            </p>
          </div>

        </div>
      ) : <div className='p-2'>&nbsp;</div>}
    </div>
  )
}


ValidatableTextField.propTypes = {
  label: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.string,
  initValue: PropTypes.string,
  isTextArea: PropTypes.bool,
  textChangedCallback: PropTypes.func,
  validationCallback: PropTypes.func,
}
