import React, {useContext, useEffect, useState, useMemo} from 'react'
import {AppStateContext} from '../../../App.state'
import debounce from 'debounce'
import redCross from '../.././../../assets/images/processing-summary/red-cross.svg'
import PropTypes from 'prop-types'

export function ValidatableField({label, inputId, actionType, errorMessage, placeholder, fieldDisabled, value}) {
  const {dispatch} = useContext(AppStateContext)
  const [inputValue, setInputValue] = useState(value)
  useEffect(() => {
    setInputValue(value)
  }, [value])

  const updateField = useMemo(() => debounce(
    (newValue) => {
      dispatch({type: actionType, value: newValue})
    },
    1000), [actionType, dispatch])

  const onFieldChange = (value) => {
    setInputValue(value)
    updateField(value)
  }

  const backgroundColor = fieldDisabled === true ? 'bg-snsw-gray-1' : ''
  const borderColor = errorMessage ? 'border-snsw-red-error' : 'border-snsw-gray-4'

  return (
    <div className='flex flex-col m-8'>
      <div className='flex'>
        <label className='w-1/2 self-center text-lg'>{label}</label>
        <input id={inputId}
               className={`w-1/2 text-right p-2 border-2 ${borderColor} ${backgroundColor} rounded text-sm`}
               onChange={({target: {value}}) => onFieldChange(value)}
               placeholder={placeholder}
               disabled={fieldDisabled}
               value={fieldDisabled ? placeholder : inputValue}
        />
      </div>

      {errorMessage && (
        <div className='flex'>
          <div className='w-1/2'>&nbsp;</div>
          <div className='w-1/2 flex mt-2'>
            <img className="w-auto h-4 mt-1 mr-2 " src={redCross} alt='validation error'/>
            <p id={inputId + '-validation-error'} className='text-sm text-red-700 '>
              {errorMessage}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}


ValidatableField.propTypes = {
  label: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  actionType: PropTypes.string,
  placeholder: PropTypes.string,
  fieldDisabled: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.string,
}
