import React from 'react'
import redCross from '../.././../assets/images/processing-summary/red-cross.svg'
import PropTypes from 'prop-types'

const createDivClassNames = ({topDivClass, subDivClass, labelClass, inputClass}) => {
  return {
    topDivClass: topDivClass || 'flex flex-col m-8',
    subDivClass: (subDivClass === null || subDivClass === undefined) ? 'flex' : subDivClass,
    labelClass: labelClass || 'w-1/2 self-center text-lg',
    inputClass: (inputClass || 'w-1/2 text-right p-2 border-2 rounded block text-sm'),
  }
}

export function ReasonForUnpayField(props) {
  const {
    label, inputId, errorMessage, initValue, textChangedCallback, validationCallback,
  } = props

  const {
    topDivClass, subDivClass, labelClass, inputClass,
  } = createDivClassNames(props)
  const borderColor = errorMessage ? 'border-snsw-red-error' : 'border-snsw-gray-4'

  const createChildren = (
    <React.Fragment>
      <label className={labelClass}>{label}</label>
      {(
        <select id={inputId} data-testid={inputId}
                      className={`${inputClass} ${borderColor}`}
                      onChange={({target: {value}}) => { textChangedCallback(value) }}
                      onBlur={validationCallback}
                      value={initValue}>
          <option/>
          <option>Network outage</option>
          <option>OBS Booking added to Open Invoice</option>
          <option>DRIVES Invoice incorrectly reversed</option>
        </select>
      )}
    </React.Fragment>)

  return (
    <div className={`${topDivClass}`}>
      {subDivClass ?
        <div className={`${subDivClass}`}>
          {createChildren}
        </div>
        :
        <div>
          {createChildren}
        </div>
      }

      {errorMessage ? (
        <div className='flex'>
          <div className='flex p-2'>
            <img className="w-auto h-4 mt-1 mr-2 " src={redCross} alt='validation error'/>
            <p id={inputId + '-validation-error'} className='text-sm text-red-700 '>
              {errorMessage}
            </p>
          </div>

        </div>
      ) : <div className='p-2'>&nbsp;</div>}
    </div>
  )
}


ReasonForUnpayField.propTypes = {
  label: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  placeholder: PropTypes.string,
  initValue: PropTypes.string,
  textChangedCallback: PropTypes.func,
  validationCallback: PropTypes.func,
}
