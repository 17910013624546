import * as yup from 'yup'
import {
  CHANGE_PAYMENT_METHOD,
  SET_CHEQUE_ACCOUNT,
  SET_CHEQUE_AMOUNT,
  SET_CHEQUE_BSB,
  SET_CHEQUE_NUMBER,
  SET_CHEQUE_TYPE,
  SET_CHEQUE_UNDERPAYMENT,
  RESET_PAYMENT_FORM,
} from '../Actions'

const alphaNumericOnly = /^[a-zA-Z0-9]*$/
const numericOnly = /^[0-9]*$/

export const ChequeStateSchema = yup.object().shape({
  chequeNumber: yup.string()
    .required('Cheque Number is mandatory and should be at least 3 characters long')
    .min(3, 'Cheque Number is mandatory and should be at least 3 characters long')
    .matches(alphaNumericOnly, 'Cheque Number is mandatory and should be at least 3 characters long'),
  bsb: yup.string()
    .required('BSB is mandatory and should be 6 characters long')
    .length(6, 'BSB is mandatory and should be 6 characters long')
    .matches(numericOnly, 'BSB is mandatory and should be 6 characters long'),
  account: yup.string()
    .required('Account Number is mandatory and should be at least 6 characters long')
    .min(6, 'Account Number is mandatory and should be at least 6 characters long')
    .matches(numericOnly, 'Account Number is mandatory and should be at least 6 characters long'),
  amount: yup.number()
    .required('Cheque Amount is mandatory and should be a number')
    .positive('Cheque Amount is mandatory and should be greater than zero'),
  underpayment: yup.bool()
    .equals([false], 'Cheque Amount is mandatory and should be at least the amount to be charged'),
})

export const chequeStateReducer = (chequeState = undefined, action) => {
  switch (action.type) {
    case SET_CHEQUE_NUMBER:
      return {
        ...chequeState,
        chequeNumber: action.value,
      }
    case SET_CHEQUE_BSB:
      return {
        ...chequeState,
        bsb: action.value,
      }
    case SET_CHEQUE_ACCOUNT:
      return {
        ...chequeState,
        account: action.value,
      }
    case CHANGE_PAYMENT_METHOD:
    case RESET_PAYMENT_FORM:
      return action.paymentMethod === 'CHEQUE' ? blankChequeState : undefined
    case SET_CHEQUE_TYPE:
      return {
        ...chequeState,
        chequeType: action.value,
      }
    case SET_CHEQUE_AMOUNT:
      return {
        ...chequeState,
        amount: action.value,
      }
    case SET_CHEQUE_UNDERPAYMENT:
      return {
        ...chequeState,
        underpayment: action.value,
      }
    default:
      return chequeState
  }
}

export const blankChequeState = {
  chequeType: 'PERSONAL',
  chequeNumber: '',
  account: '',
  bsb: '',
  amount: 0,
  underpayment: false,
}
