import React from 'react'
import PropTypes from 'prop-types'
import {PaymentStatusMessage} from '../PaymentStatusMessage'
import greenTick from '../../../assets/images/processing-summary/green-tick.svg'

export function ChequeStatusMessage(props) {
  return (
    <div>
      <PaymentStatusMessage
        className={props.className}
        image={greenTick}
        message={props.message}
      />
    </div>
  )
}

ChequeStatusMessage.propTypes = {
  message: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
}

export function CollectedChequeStatusMessage(props) {
  const message = `Cheque payment of $${props.amount} collected - Cheque number ${props.chequeNumber}`
  return<ChequeStatusMessage className='cheque-collected' message={message}/>
}

CollectedChequeStatusMessage.propTypes = {
  amount: PropTypes.number.isRequired,
  chequeNumber: PropTypes.string.isRequired,
}

export function VoidedChequeStatusMessage(props) {
  const message = `Payment voided, please return cheque number ${props.chequeNumber} to the customer`
  return <ChequeStatusMessage className='cheque-voided' message={message}/>
}

VoidedChequeStatusMessage.propTypes = {
  chequeNumber: PropTypes.string.isRequired,
}
