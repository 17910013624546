import React from 'react'
import * as yup from 'yup'
import {chequeStateReducer, ChequeStateSchema} from './payment-form/collect-payment/options/cheque/Cheque.state'
import {
  moneyOrderStateReducer,
  MoneyOrderStateSchema,
} from './payment-form/collect-payment/options/moneyOrder/MoneyOrder.state'
import {cashStateReducer, CashStateSchema} from './payment-form/collect-payment/options/cash/Cash.state'
import {cardStateReducer, CardStateSchema} from './payment-form/collect-payment/options/card/Card.state'
import {ADD_TO_TOTAL, PROCESS_UNDERPAYMENT, RESET_PAYMENT_PROCESS} from './payment-form/collect-payment/options/Actions'

export const AppStateContext = React.createContext({})
export const AppStateSchema = yup.object().shape({
  chequePayment: ChequeStateSchema,
  cashPayment: CashStateSchema,
  moneyOrderPayment: MoneyOrderStateSchema,
  cardPayment: CardStateSchema,
  paymentComponents: yup.array(),
  rateLimitExceeded: false,
})

export const initialAppState = {
  chequePayment: {},
  moneyOrderPayment: {},
  cashPayment: {},
  cardPayment: {},
  paymentComponents: [],
  payment: {},
}

export function appReducer(prevState = initialAppState, action) {
  if (action.type === RESET_PAYMENT_PROCESS) {
    return initialAppState
  }

  return {
    chequePayment: chequeStateReducer(prevState.chequePayment, action),
    cashPayment: cashStateReducer(prevState.cashPayment, action),
    moneyOrderPayment: moneyOrderStateReducer(prevState.moneyOrderPayment, action),
    cardPayment: cardStateReducer(prevState.cardPayment, action),
    paymentComponents: (() => {

      switch (action.type) {
        case ADD_TO_TOTAL: {
          const previous = action.value.componentType !== 'CASH' ?
            prevState.paymentComponents :
            prevState.paymentComponents.filter(component => component.componentType !== 'CASH')

          return [
            ...previous,
            action.value]
        }
        case PROCESS_UNDERPAYMENT : {
          const previous = prevState.paymentComponents

          return [
            ...previous,
            action.value,
          ]
        }
        default:
          return prevState.paymentComponents
      }
    })(),
  }
}
