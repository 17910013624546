import React from 'react'
import PropTypes from 'prop-types'
import {PaymentStatusMessage} from '../PaymentStatusMessage'
import greySpinner from '../../../assets/images/processing-summary/grey-spinner.svg'
import greenTick from '../../../assets/images/processing-summary/green-tick.svg'
import redCross from '../../../assets/images/processing-summary/red-cross.svg'

VoidCardStatusMessage.propTypes = {
  amount: PropTypes.number.isRequired,
  cardType: PropTypes.string.isRequired,
  cardEndDigits: PropTypes.string.isRequired,
  cardPaymentStatus: PropTypes.string.isRequired,
  retryCardCallback: PropTypes.func.isRequired,
}

export function VoidCardStatusMessage(props) {

  const failureMsgs = {
    VOID_FAILED: 'Card void processing failed: Payment was unsuccessful',
    VOID_UNKNOWN: 'Card void processing failed: Payment status is unknown',
  }

  const loadingMsgs = {
    SENT_TO_PINPAD: 'Card payment void requested',
    CONNECTING_TO_PINPAD: 'Card payment void requested',
    PINPAD_CONNECT_SUCCESS: 'Card payment void requested',
    VOID_REQUESTED: 'Card payment void requested',
  }

  const {amount, cardType, cardEndDigits, cardPaymentStatus} = props

  if (cardPaymentStatus in loadingMsgs) {
    return (
      <div>
        <PaymentStatusMessage image={greySpinner} className='card-payment-void-requested'
                              message={`${loadingMsgs[cardPaymentStatus]} for $${amount} - Card ${cardType.toUpperCase()} ${cardEndDigits}`}/>
      </div>
    )
  }

  if (cardPaymentStatus === 'VOIDED') {
    return (
      <div>
        <PaymentStatusMessage image={greenTick} className='card-payment-voided'
                              message={`Card payment voided for $${amount} - Card ${cardType.toUpperCase()} ${cardEndDigits}`}/>
      </div>
    )
  }

  if (cardPaymentStatus in failureMsgs) {
    return (
      <div>
        <PaymentStatusMessage image={redCross} className='card-payment-void-failed'
                              message={`${failureMsgs[cardPaymentStatus]} ($${amount} - Card ${cardType.toUpperCase()} ${cardEndDigits})`}/>
        <button
          className='retry ml-4 mt-10 mb-4 rounded-md bg-primary px-6 py-2 font-bold text-white text-center'
          onClick={props.retryCardCallback}>
          Retry
        </button>
      </div>
    )
  }
  return <div/>
}
