import React from 'react'
import PropTypes from 'prop-types'
import {PaymentStatusMessage} from '../PaymentStatusMessage'
import greySpinner from '../../../assets/images/processing-summary/grey-spinner.svg'
import greenTick from '../../../assets/images/processing-summary/green-tick.svg'
import redCross from '../../../assets/images/processing-summary/red-cross.svg'
import {CardFailureRecoveryOptions} from './CardFailureRecoveryOptions'

CardStatusMessage.propTypes = {
  amount: PropTypes.number.isRequired,
  cardType: PropTypes.string.isRequired,
  cardEndDigits: PropTypes.string.isRequired,
  cardPaymentStatus: PropTypes.string.isRequired,
  useAnotherPaymentMethodCallback: PropTypes.func.isRequired,
  retryCardCallback: PropTypes.func.isRequired,
  cancelPaymentCallback: PropTypes.func.isRequired,
  hideVoidOptions: PropTypes.bool.isRequired,
}

export function CardStatusMessage(props) {

  const failureMsgs = {
    CANCELLED: 'Card processing failed: Payment was unsuccessful',
    FAILED: 'Card processing failed: Payment was unsuccessful',
    UNKNOWN: 'Card processing failed: Payment status is unknown',
    POWER_FAILURE: 'Card processing failed: Payment status is unknown',
    TIMEOUT: 'Card processing failed: Payment status is unknown',
    UNKNOWN_TO_CPP: 'Card processing failed: Payment status is unknown',
    LAST_TENDER_STATUS_TIMEOUT: 'Card processing failed: Payment status is unknown',
  }

  const loadingMsgs = {
    SENT_TO_PINPAD:'sent to pinpad',
    CONNECTING_TO_PINPAD:'sent to pinpad',
    PINPAD_CONNECT_SUCCESS:'sent to pinpad',
  }

  const {amount, cardType, cardEndDigits, cardPaymentStatus} = props

  if (cardPaymentStatus in loadingMsgs) {
    return (
      <div>
        <PaymentStatusMessage image={greySpinner} className='card-payment-requested'
                              message={`Card payment request for $${amount} sent to pinpad`}/>
      </div>
    )
  }

  if (cardPaymentStatus === 'APPROVED' || cardPaymentStatus === 'VOIDED' || cardPaymentStatus === 'VOID_REQUESTED') {
    return (
      <div>
        <PaymentStatusMessage image={greenTick} className='card-payment-collected'
                              message={`Card payment for $${amount} collected - Card ${(cardType || '').toUpperCase()} ${cardEndDigits}`}/>
      </div>
    )
  }

  if (cardPaymentStatus === 'DECLINED') {
    return (
      <div>
        <PaymentStatusMessage image={redCross} className='card-payment-failed'
                              message={`Card payment for $${amount} declined: Payment was unsuccessful`}/>
        <CardFailureRecoveryOptions
          useAnotherPaymentMethodCallback={props.useAnotherPaymentMethodCallback}
          retryCardCallback={props.retryCardCallback}
          cancelPaymentCallback={props.cancelPaymentCallback}
        />
      </div>
    )
  }

  if (cardPaymentStatus in failureMsgs) {
    return (
      <div>
        <PaymentStatusMessage image={redCross} className='card-payment-failed'
                              message={failureMsgs[cardPaymentStatus]}/>
        {!props.hideVoidOptions && (<CardFailureRecoveryOptions
          useAnotherPaymentMethodCallback={props.useAnotherPaymentMethodCallback}
          retryCardCallback={props.retryCardCallback}
          cancelPaymentCallback={props.cancelPaymentCallback}
        />)}
      </div>
    )
  }

  if (cardPaymentStatus === 'PINPAD_CONNECT_FAILED') {
    return (
      <div>
        <PaymentStatusMessage image={redCross} className='card-payment-failed'
                              message='Unable to connect to pinpad'/>
        {!props.hideVoidOptions && (<CardFailureRecoveryOptions
          useAnotherPaymentMethodCallback={props.useAnotherPaymentMethodCallback}
          retryCardCallback={props.retryCardCallback}
          cancelPaymentCallback={props.cancelPaymentCallback}
        />)}
      </div>
    )
  }
  return <div/>
}
