import React, {useEffect, useState} from 'react'
import * as PropType from 'prop-types'
import PropTypes from 'prop-types'
import axios from 'axios'
import refundPdfDealer from '../../../docs/EFT_Refund_Registration_Dealer_Overpayments.pdf'
import refundPdfSingleParty from '../../../docs/Refund_Request_Form_V4_Final.pdf'

function ChequeComponent(props) {
  const {
    chequeRefundTypeSelectedCallback,
  } = props

  return (
    <div className="flex items-top gap-3 border-2 p-2 rounded block text-sm w-full border-snsw-gray-4">
      <input id="cheque" name="refund-mode" value="refund-mode-cheque" type="radio" className="w-6 h-6"
             onChange={({target: {value}}) => {
               chequeRefundTypeSelectedCallback(value)
             }}
             defaultChecked={true} data-testid="cheque"/>
      <div className="flex flex-col col-span-12 left gap-3">
        <label htmlFor="cheque" className="font-bold ">Cheque</label>
        <div className="flex gap-3 flex-wrap">
          <div id="chequeRefundLine1">Cheque will be mailed to the address provided by RMS.</div>
        </div>
      </div>
    </div>
  )
}

export function EftComponent(props) {
  const {
    customerId,
    customerReceiptType,
    eftRefundTypeSelectedCallback,
  } = props

  const DEALER = 'DL'
  const [eftAccountName, setEftAccountName] = useState('')
  const [eftAccountBSB, setEftAccountBSB] = useState('')
  const [eftAccountNumber, setEftAccountNumber] = useState('')
  const [eftComponentEnabled, setEftComponentEnabled] = useState(true)
  const [dealerEFT, setDealerEFT] = useState(false)

  useEffect(() => {
    const fetchBankAccountDetails = async () => {
      let response
      if (customerReceiptType === DEALER) {
        setDealerEFT(true)
        try {
          response = await axios.get(
            `${process.env.REACT_APP_CPP_OTC_BFF_URL || ''}/api/payments/customer/${customerId}`
          )
        } catch (error) {
          console.log(error.stack)
          setEftComponentEnabled(false)
          response = error.response
        }
        if (response.data && response.data.accountHolderName && response.data.accountHolderName && response.data.accountHolderName) {
          setEftAccountName(response.data.accountHolderName)
          setEftAccountBSB(response.data.bsb)
          setEftAccountNumber(response.data.accountNumber)
          setEftComponentEnabled(true)
        } else {
          setEftComponentEnabled(false)
        }
      } else {
        setDealerEFT(false)
        setEftComponentEnabled(true)
      }
    }
    fetchBankAccountDetails().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex items-top gap-3 border-2 p-2 rounded block text-sm w-full border-snsw-gray-4">
      <input id="eft" name="refund-mode" value="refund-mode-eft"
             onChange={({target: {value}}) => {
               eftRefundTypeSelectedCallback(value)
             }}
             type="radio" className="w-6 h-6" disabled={props.isEftDisabled || !eftComponentEnabled} data-testid="eft"/>
      <div className="flex flex-col left gap-3">
        <label htmlFor="eft" className="font-bold">Electronic Fund Transfer (Max $500)</label>
        {
          !dealerEFT &&
          (
            <div>
              <div className="flex gap-3 flex-wrap">
                <div id="singlePartyEftRefundLine1">
                  <a href={refundPdfSingleParty} className="font-bold text-snsw-blue-2"
                     target="_blank" rel="noopener noreferrer">Download</a> EFT refund request form and present it to
                  the customer.
                </div>
              </div>
              <div className="flex gap-3 flex-wrap">
                <div id="singlePartyEftRefundLine2">Once completed, scan the form and email it to Finance.</div>
              </div>
            </div>
          )
        }
        {
          dealerEFT &&
          (
            <div>
              <div className="flex gap-3 flex-wrap">
                <div>Account Name:</div>
                <div id="eftAccountName">{eftAccountName}</div>
              </div>
              <div className="flex gap-3 flex-wrap">
                <div>BSB:</div>
                <div id="eftAccountBSB">{eftAccountBSB}</div>
                <div>Account Number:</div>
                <div id="eftAccountNumber">{eftAccountNumber}</div>
              </div>
              <div className="flex gap-3 flex-wrap">
                <div id="dealerEftRefundLine1">
                  <a href={refundPdfDealer} className="font-bold text-snsw-blue-2" target="_blank"
                     rel="noopener noreferrer">Download</a> EFT refund registration form.
                </div>
              </div>
            </div>
          )
        }
      </div>
    </div>
  )
}

export function OverpaymentRefundModes(props) {
  const {
    chequeOnlyRefundType,
    refundTypeSelectedCallback,
    customerId,
    customerReceiptType,
  } = props

  return (
    <React.Fragment>
      {
        <div className="flex flex-col gap-3">
          <ChequeComponent chequeRefundTypeSelectedCallback={value => {
            refundTypeSelectedCallback(value)
          }}/>
          <EftComponent customerId={customerId}
                        customerReceiptType={customerReceiptType}
                        isEftDisabled={chequeOnlyRefundType}
                        eftRefundTypeSelectedCallback={value => {
                          refundTypeSelectedCallback(value)
                        }}/>
        </div>
      }
    </React.Fragment>)
}

OverpaymentRefundModes.propTypes = {
  chequeOnlyRefundType: PropType.bool.isRequired,
  refundTypeSelectedCallback: PropTypes.func,
  customerId: PropTypes.string.isRequired,
  customerReceiptType: PropTypes.string.isRequired,
}

ChequeComponent.propTypes = {
  chequeRefundTypeSelectedCallback: PropTypes.func,
}

EftComponent.propTypes = {
  customerId: PropTypes.string.isRequired,
  isEftDisabled: PropType.bool.isRequired,
  eftRefundTypeSelectedCallback: PropTypes.func,
  customerReceiptType: PropTypes.string.isRequired,
}
