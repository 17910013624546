import React, {useReducer} from 'react'
import App from './App'
import {appReducer, AppStateContext, initialAppState} from './App.state'
import PropTypes from 'prop-types'


export default function StatefulApp(props) {
  const [state, dispatch] = useReducer(appReducer, initialAppState)
  return (
    <AppStateContext.Provider value={{appState: Object.freeze(state), dispatch}}>
      <App paymentReference={props.paymentReference} refundReference={props.refundReference}/>
    </AppStateContext.Provider>
  )
}

StatefulApp.propTypes = {
  paymentReference: PropTypes.string,
  refundReference: PropTypes.string,
  stream: PropTypes.bool,
}
