import React from 'react'
import PropTypes from 'prop-types'
import {PaymentStatusMessage} from '../PaymentStatusMessage'
import greySpinner from '../../../assets/images/processing-summary/grey-spinner.svg'
import greenTick from '../../../assets/images/processing-summary/green-tick.svg'
import redCross from '../../../assets/images/processing-summary/red-cross.svg'

RefundCardStatusMessage.propTypes = {
  amount: PropTypes.number.isRequired,
  cardType: PropTypes.string.isRequired,
  cardEndDigits: PropTypes.string.isRequired,
  cardRefundStatus: PropTypes.string.isRequired,
}

export function RefundCardStatusMessage(props) {

  const failureMsgs = {
    DECLINED: 'Card refund processing failed: Payment was unsuccessful',
    POWER_FAILURE: 'Card refund processing failed: Payment status is unknown',
    UNKNOWN: 'Card refund processing failed: Payment status is unknown',
    TIMEOUT: 'Card refund processing failed: Payment status is unknown',
    UNKNOWN_TO_CPP: 'Card refund processing failed: Payment status is unknown',
    CANCELLED: 'Card refund processing failed: Payment was unsuccessful',
    FAILED: 'Card refund processing failed: Payment was unsuccessful',
    LAST_TENDER_STATUS_TIMEOUT: 'Card refund processing failed: Payment status is unknown',
  }

  const {amount, cardType, cardEndDigits, cardRefundStatus} = props

  if (cardRefundStatus === 'IN_PROGRESS') {
    return (
      <div>
        <PaymentStatusMessage image={greySpinner} className='card-payment-refund-requested'
                                message={`Card payment refund requested for $${amount}`}/>
      </div>
    )
  }

  if (cardRefundStatus === 'APPLIED') {
    return (
      <div>
        <PaymentStatusMessage image={greenTick} className='card-payment-refunded'
                                message={`Card payment refunded for $${amount} - Card ${cardType.toUpperCase()} ${cardEndDigits}`}/>
      </div>
    )
  }

  if (cardRefundStatus in failureMsgs) {
    return (
      <div>
        <PaymentStatusMessage image={redCross} className='card-payment-refund-failed'
                              message={`${failureMsgs[cardRefundStatus]}`}/>
      </div>
    )
  }
  return <div/>
}
