import * as yup from 'yup'
import {
  CHANGE_PAYMENT_METHOD,
  SET_CASH_AMOUNT,
  SET_CASH_TENDERED_AMOUNT,
} from '../Actions'

yup.setLocale({
  mixed: {
    notType: ({ type}) => {
      return `Must be a ${type}`
    },
  },
})

export const CashStateSchema = yup.object().shape({
  tenderedAmount: yup.number()
    .required('Tendered amount is mandatory')
    .when('amount', (roundedAmount, schema) => schema.min(roundedAmount, 'Tendered amount cannot be less than the requested amount')),
  amount: yup.number()
    .positive('Amount should be greater than zero')
    .required('Amount is mandatory'),
})

export const cashStateReducer = (cashState = undefined, action) => {
  switch (action.type) {
    case SET_CASH_TENDERED_AMOUNT:
      return {
        ...cashState,
        tenderedAmount: action.value,
      }
    case SET_CASH_AMOUNT:
      return {
        ...cashState,
        amount: action.value,
      }
    case CHANGE_PAYMENT_METHOD:
      return action.paymentMethod === 'CASH' ? blankCashState : undefined
    default:
      return cashState
  }
}

export const blankCashState = {
  tenderedAmount: undefined,
  amount: undefined,
}
