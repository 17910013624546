import React, {useState} from 'react'
import Modal from 'react-modal'
import * as PropTypes from 'prop-types'
import * as yup from 'yup'
import {ValidatableTextField} from './processing-summary/override/ValidatableTextField'
import axios from 'axios'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    paddingLeft: '2.5em',
    paddingRight: '2.5em',
    paddingBottom: '1em',
    paddingTop: '1em',
  },
  overlay: {background: 'rgba(0,0,0,0.5)'},
}

const chooseNonEmpty = (a, b) => (!a || a === '' ? b : a)

const OverrideModalSchema = yup.object().shape({
  supervisorEmail: yup.string().required('Email is required'),
  supervisorCode: yup.string().required('Override Code is required'),
})
export const OverrideModal = ({
  showModal,
  closeModal,
  approve,
  approveText,
  approveEnabled,
  children,
  title,
  errorMsg,
}) => {
  const [supervisorDetails, setSupervisorDetails] = useState({
    supervisorEmail: {value: '', error: ''},
    supervisorCode: {value: '', error: ''},
  })

  const [serverMessageState, setServerMessageState] = useState({
    supervisorEmailError: '',
    supervisorCodeError: '',
  })

  const parentApproveEnabled =
        approveEnabled &&
        supervisorDetails.supervisorCode.value !== '' &&
        supervisorDetails.supervisorEmail.value !== ''

  const approveDisableStyle = parentApproveEnabled
    ? ''
    : 'opacity-50 pointer-events-none select-none"'

  const validateFieldInner = (fieldName, fieldValue) => {
    const VALID_RESULT = {isValid: true}
    try {
      OverrideModalSchema.validateSyncAt(
        fieldName,
        {[fieldName]: fieldValue},
        {strict: true}
      )
      return VALID_RESULT
    } catch (error) {
      return {
        isValid: false,
        errorMessage: error.message,
      }
    }
  }

  const validateField = (fieldName) => {
    const validation = validateFieldInner(fieldName, supervisorDetails[fieldName].value)
    if (!validation.isValid) {
      setSupervisorDetails({
        ...supervisorDetails,
        [fieldName]: {...supervisorDetails[fieldName], error: validation.errorMessage},
      })
    } else {
      setSupervisorDetails({...supervisorDetails, [fieldName]: {...supervisorDetails[fieldName], error: ''}})
    }
  }

  const supervisorEmailValidate = () => validateField('supervisorEmail')
  const supervisorOverrideCodeValidate = () => validateField('supervisorCode')

  const resetAndCloseModal = () => {
    setSupervisorDetails({
      supervisorEmail: {value: '', error: ''},
      supervisorCode: {value: '', error: ''},
    })
    setServerMessageState({
      supervisorEmailError: '',
      supervisorCodeError: '',
    })
    closeModal()
  }

  const handleOverridePaymentApiCall = async (
    overrideDetails,
    overrideAction
  ) => {
    const overrideResult = await overrideAction(overrideDetails)

    if (overrideResult.status === 200) {
      closeModal()
    } else {
      const INVALID_SUPERVISOR_DETAILS = 'Unauthorised: You do not have the permission to approve this override'
      setServerMessageState({
        ...serverMessageState,
        supervisorEmailError: INVALID_SUPERVISOR_DETAILS,
        supervisorCodeError: INVALID_SUPERVISOR_DETAILS,
      })
    }
  }

  const handleApproveClick = (e) => {
    e.preventDefault()

    const overrideDetails = {
      supervisorId: supervisorDetails.supervisorEmail.value,
      supervisorCode: supervisorDetails.supervisorCode.value,
    }
    approve(overrideDetails, handleOverridePaymentApiCall)
  }

  return (
    <div>
      <Modal
                isOpen={showModal}
                style={customStyles}
                ariaHideApp={false}
                onRequestClose={closeModal}
      >
        <div className="flex-1 text-2xl font-bold mt-5 mb-5">{title}</div>
        <form
                    className="flex flex-col h-full text-sm"
                    onSubmit={(e) => {
                      e.preventDefault()
                    }}
        >
          {children}
          <ValidatableTextField
                        label="Supervisor email"
                        inputId="supervisor-email"
                        topDivClass="flex flex-col"
                        subDivClass="w-full mt-1"
                        inputClass="p-2 border-2 rounded block text-sm w-full"
                        labelClass="font-bold"
                        inputType="text"
                        textChangedCallback={(value) =>
                          setSupervisorDetails({...supervisorDetails, supervisorEmail: {value, error: ''}})
                        }
                        initValue={supervisorDetails.supervisorEmail.value}
                        errorMessage={chooseNonEmpty(supervisorDetails.supervisorEmail.error)}
                        validationCallback={supervisorEmailValidate}
                        placeholder="Please enter supervisor email"
          />

          <ValidatableTextField
                        label="Supervisor override code"
                        inputId="override-code"
                        topDivClass="flex flex-col"
                        subDivClass="w-full mt-1"
                        inputClass="p-2 border-2 rounded block text-sm w-full"
                        labelClass="font-bold"
                        inputType="password"
                        textChangedCallback={(value) =>
                          setSupervisorDetails({
                            ...supervisorDetails,
                            supervisorCode: {value, error: ''},
                          })
                        }
                        initValue={supervisorDetails.supervisorCode.value}
                        errorMessage={chooseNonEmpty(supervisorDetails.supervisorCode.error)}
                        validationCallback={supervisorOverrideCodeValidate}
                        placeholder="Please enter supervisor code"
          />
          <span className="text-sm text-red-700 max-w-lg" id="customerReceiptType-error">
            {chooseNonEmpty(errorMsg)}
          </span>
          <div className="font-bold mt-3 mx-auto">
            {approveText || 'I approve this override with the above detail'}
          </div>
          <span className="text-sm text-red-700" id="validation-server-error">
            {chooseNonEmpty(serverMessageState.supervisorEmailError)}
          </span>
          <div className="mx-auto">
            <button
                            onClick={resetAndCloseModal}
                            data-testid="cancel-button"
                            className="mx-6 mt-6 mb-4 rounded-md bg-snsw-gray-2 px-12 py-2 border-2 border-snsw-gray-5 font-bold text-snsw-blue-2 content-around"
            >
                            Cancel
            </button>
            <button
                            data-testid="approve-button"
                            disabled={!parentApproveEnabled}
                            onClick={handleApproveClick}
                            className={`mx-6 mt-6 mb-4 ${approveDisableStyle} bg-primary rounded-md px-12 py-2 font-bold text-white`}
            >
                            Approve
            </button>
          </div>
        </form>
      </Modal>
    </div>
  )
}

OverrideModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  approveEnabled: PropTypes.bool.isRequired,
  approveText: PropTypes.string,
  children: PropTypes.node,
  closeModal: PropTypes.func.isRequired,
  approve: PropTypes.func.isRequired,
  errorMsg: PropTypes.string,
}

export const validateCsrCredentialsApiCall = async ({
  paymentReference,
  supervisorId,
  supervisorCode,
}) => {
  try {
    return await axios.post(
      `${process.env.REACT_APP_CPP_OTC_BFF_URL || ''}/api/payments/${paymentReference}/adjustment-override`,
      {
        supervisorId,
        supervisorCode,
      }
    )
  } catch (error) {
    return error.response
  }
}

export default OverrideModal
