import React, {useContext, useEffect, useState} from 'react'
import OverrideModal, {validateCsrCredentialsApiCall} from '../../OverrideModal'
import * as PropType from 'prop-types'
import PropTypes from 'prop-types'
import {StringUtil} from '../../payment-form/StringUtil'
import {ValidatableTextField} from '../override/ValidatableTextField'
import redCross from '../../../assets/images/processing-summary/red-cross.svg'
import * as yup from 'yup'
import {reasonCodes} from './reasonCodes'
import axios from 'axios'
import {PROCESS_UNDERPAYMENT} from '../../payment-form/collect-payment/options/Actions'
import {AppStateContext} from '../../App.state'

const UnderpaymentOverrideModalSchema = yup.object().shape({
  logRequired: yup.boolean(),
  logType: yup.string(),
  logNumber: yup.string()
    .matches(/^[a-zA-Z0-9]*$/, 'Invalid Character(s) - Only numbers and letters allowed in Log Number')
    .when('logRequired', {
      is: true,
      then: (schema) => schema.required('Log Number is required'),
    })
    .when('logType', {
      is: 'DRIVES',
      then: (schema) => schema.matches(/^(DHD)[0-9]{8,10}$/, 'Enter a valid DHD Log number'),
    })
    .when('logType', {
      is: 'SNSW',
      then: (schema) => schema.matches(/^(INC)[0-9]{7,10}$/, 'Enter a valid SNSW Log number'),
    })
    .when('logType', {
      is: 'FIN',
      then: (schema) => schema.matches(/^(FIN)[0-9]{6,10}$/, 'Enter a valid FIN Log number'),
    })
    .max(20, 'Maximum of 20 characters can be entered'),
  comments: yup.string()
    .required('Comments cannot be blank')
    .max(255, 'Maximum of 255 characters can be entered'),
})

const UnderpaymentOverrideModal = ({
  showModal,
  closeModal,
  title,
  invoiceId,
  amountRequested,
  amountTendered,
  paymentReference,
  approveCallback,
}) => {
  const {appState, dispatch} = useContext(AppStateContext)
  const [underpaymentDetails, setUnderpaymentDetails] = useState({
    reason: {value: '', error: ''},
    logNumber: {value: '', error: ''},
    comments: {value: '', error: ''},
  })
  const [sendUnderpaymentForProcessing, setSendUnderpaymentForProcessing] = useState(false)
  useEffect(() => {
    if (sendUnderpaymentForProcessing) {
      approveCallback()
      setSendUnderpaymentForProcessing(false)
    }

  }, [appState.paymentComponents, sendUnderpaymentForProcessing, approveCallback])

  const handleApproveClick = (
    parentOverrideDetails,
    handleOverridePaymentApiCall
  ) => {

    const overrideDetails = {
      ...parentOverrideDetails,
      paymentReference,
    }

    handleOverridePaymentApiCall(overrideDetails, underpaymentOverride)

  }

  const underpaymentOverride = async (parentOverrideDetails) => {
    const overrideResult = await validateCsrCredentialsApiCall(
      parentOverrideDetails
    )

    if (overrideResult.status === 200) {
      const agencyCsrId = overrideResult.data
      const adjustmentDetails = {
        paymentReference,
        agencyCsrId,
      }
      const adjustmentResult = await saveAdjustmentComponent(adjustmentDetails)

      if (adjustmentResult.status === 200) {
        const underpaymentComponentDetails = {
          amount: amountRequested - amountTendered,
          componentType: 'ADJUSTMENT',
          adjustmentType: 'UNDERPAYMENT',
        }
        dispatch({type: PROCESS_UNDERPAYMENT, value: underpaymentComponentDetails})
        setSendUnderpaymentForProcessing(true)
      } else {
        return adjustmentResult
      }
    }
    return overrideResult
  }

  const saveAdjustmentComponent = async ({
    paymentReference,
    agencyCsrId,
  }) => {
    try {
      const amount = amountRequested - amountTendered
      const type = 'UNDERPAYMENT'
      const supervisorCsrId = agencyCsrId
      return await axios.post(
        `${process.env.REACT_APP_CPP_OTC_BFF_URL || ''}/api/payments/adjustment`,
        {
          paymentReference,
          amount,
          type,
          supervisorCsrId,
          reasonDescription: getReasonText(),
          logNumber: underpaymentDetails['logNumber'].value,
          comments: underpaymentDetails['comments'].value,
        }
      )
    } catch (error) {
      console.log(error.response)
      return error.response
    }
  }

  const getReasonCodes = () => {
    return reasonCodes
      .filter(item => item.limit === 0 || item.limit >= (amountRequested - amountTendered))
      .map((item, i) =>
        <option key={i} value={item.code}>{item.text}</option>
      , this)
  }

  const getReasonText = () => {
    return reasonCodes.find(x => x.code === parseInt(underpaymentDetails['reason'].value))?.text
  }

  const getDescription = () => {
    return reasonCodes.find(x => x.code === parseInt(underpaymentDetails['reason'].value))?.desc
  }

  const getPlaceholder = () => {
    return reasonCodes.find(x => x.code === parseInt(underpaymentDetails['reason'].value))?.logPlaceholder
  }

  const getLogRequired = () => {
    return reasonCodes.find(x => x.code === parseInt(underpaymentDetails['reason'].value))?.logRequired
  }

  const getLogType = () => {
    return reasonCodes.find(x => x.code === parseInt(underpaymentDetails['reason'].value))?.logType
  }

  const validateEntries = () => {
    return validOutstandingAmount()
            && underpaymentDetails['reason'].value
            && underpaymentDetails['reason'].value.length > 0
            && underpaymentDetails['reason'].value !== '0'
            && !underpaymentDetails.logNumber.error && ((getLogRequired() && !!underpaymentDetails.logNumber.value)
                || !getLogRequired())
            && !underpaymentDetails.comments.error && !!underpaymentDetails.comments.value
  }

  const validOutstandingAmount = () => {
    return amountRequested - amountTendered > 0
  }

  const validateFieldInner = (fieldName, fieldValue, logRequired, logType) => {
    const VALID_RESULT = {isValid: true}
    try {
      UnderpaymentOverrideModalSchema.validateSyncAt(
        fieldName,
        {logRequired: logRequired, [fieldName]: fieldValue, logType: logType},
        {strict: true}
      )
      return VALID_RESULT
    } catch (error) {
      return {
        isValid: false,
        errorMessage: error.message,
      }
    }
  }

  const validateField = (fieldName) => {
    const validation = validateFieldInner(fieldName,
      underpaymentDetails[fieldName].value,
      getLogRequired(), getLogType())
    if (!validation.isValid) {
      setUnderpaymentDetails({
        ...underpaymentDetails,
        [fieldName]: {...underpaymentDetails[fieldName], error: validation.errorMessage},
      })
    } else {
      setUnderpaymentDetails({
        ...underpaymentDetails,
        [fieldName]: {...underpaymentDetails[fieldName], error: ''},
      })
    }
  }

  const logNumberValidate = () => validateField('logNumber')
  const commentsValidate = () => validateField('comments')

  return (
    <OverrideModal
            showModal={showModal}
            approve={handleApproveClick}
            approveText="I approve this underpayment with the above details."
            title={title}
            closeModal={closeModal}
            approveEnabled={validateEntries()}
    >
      <div className="grid grid-cols-12 gap-2 max-w-lg border-b border-snsw-gray-6 pb-2">
        <div className="col-span-7 font-bold">Invoice Number</div>
        <div id="invoiceId" className="col-span-5 text-right pb-1">{invoiceId}</div>
        <div className="col-span-7">Amount payable</div>
        <div id="amountRequested" className="col-span-5 text-right">
          {StringUtil.formattedAmount(amountRequested)}
        </div>
        <div className="col-span-7">Amount Outstanding</div>
        <div id="outstandingAmount" className="col-span-5 font-bold text-right">
          {StringUtil.formattedAmount(amountRequested - amountTendered)}
        </div>
      </div>
      {!validOutstandingAmount() &&
                <div className='flex p-2'>
                  <img className="w-auto h-4 mt-1 mr-2 " src={redCross} alt='validation error'/>
                  <span className="text-sm text-red-700 max-w-lg" id="zeroOutstandingAmount-error">
              Cannot add underpayments. No amount is outstanding.
                  </span>
                </div>
      }
      <div className="flex flex-col pt-6">
        <label className="font-bold">Reason Code</label>
        <select id="reason-code" data-testid="reason-code"
                className="w-full p-2 border-2 rounded block text-sm"
                onChange={({target: {value}}) =>
                  setUnderpaymentDetails({
                    ...underpaymentDetails,
                    reason: {value, error: ''},
                    logNumber: {value: '', error: ''},
                  })
                }>
          <option key="0" value="0">- Select -</option>
          {getReasonCodes()}
        </select>
        <div className='underpayment-reason-code-description'>
          <p>
            {getDescription()}
          </p>
        </div>
        <ValidatableTextField
            label="Log Number"
            inputId="log-number"
            topDivClass="mt-0"
            subDivClass="flex w-full mt-1"
            inputClass=" flex-1 p-2 border-2 rounded block text-sm w-full"
            labelClass="font-bold mr-2 mt-3"
            inputType="text"
            textChangedCallback={(value) =>
              setUnderpaymentDetails({...underpaymentDetails, logNumber: {value, error: ''}})
            }
            errorMessage={underpaymentDetails.logNumber.error}
            validationCallback={logNumberValidate}
            placeholder={getPlaceholder()}
            initValue={underpaymentDetails.logNumber.value}
        />
        <ValidatableTextField
            label="Comments"
            inputId="comments"
            topDivClass="flex flex-col"
            subDivClass="w-full mt-1"
            inputClass="p-2 border-2 rounded block text-sm w-full"
            labelClass="font-bold"
            inputType="textarea"
            textChangedCallback={(value) =>
              setUnderpaymentDetails({...underpaymentDetails, comments: {value, error: ''}})
            }
            errorMessage={underpaymentDetails.comments.error}
            validationCallback={commentsValidate}
            initValue={underpaymentDetails.comments.value}
        />
      </div>
    </OverrideModal>
  )
}

UnderpaymentOverrideModal.propTypes = {
  showModal: PropType.bool.isRequired,
  closeModal: PropType.func.isRequired,
  title: PropType.string.isRequired,
  invoiceId: PropType.string.isRequired,
  amountRequested: PropType.number.isRequired,
  amountTendered: PropType.number.isRequired,
  paymentReference: PropTypes.string.isRequired,
  approveCallback: PropTypes.func.isRequired,
}

export default UnderpaymentOverrideModal
