import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import PollingStatusProvider from './PollingStatusProvider'
import StreamingStatusProvider from './StreamingStatusProvider'
import {RefundCardStatusMessage} from './card/RefundCardStatusMessage'
import {StringUtil} from '../payment-form/StringUtil'

export class RefundForm extends React.Component {

   state = {
     refundStatus: this.props.refund.status,
     component: null,
     lastRefundStatus: null,
     streaming: false,
   }

   getStreamingFeatureFlag = async (csrId) => {
     try {
       const streamingFlag = await axios.get(
         `${process.env.REACT_APP_CPP_OTC_BFF_BASE_URL || ''}/api/featureflags/status.streaming/${csrId}`)
       this.setState({streaming: streamingFlag.data.value === 'true'})
     } catch (error) {
       console.log(error)
       this.setState({streaming: false})
     }
   }

   async processRefund() {
     await this.getStreamingFeatureFlag(this.props.csrId)

     if (this.state.streaming) {
       this.statusUpdateProvider = new StreamingStatusProvider(this.props.refund.refundReference)
     } else {
       this.statusUpdateProvider = new PollingStatusProvider(this.props.refund.refundReference, 'refund')
     }

     this.statusUpdateProvider.onStatusUpdate(({refundStatus, component}) => {
       this.setState({refundStatus, component})
     })
     this.props.processRefundCallback()
   }

   async cancelRefundPayment() {
     const refundCancelResponse = await axios.post(`/api/refunds/${this.props.refund.refundReference}/cancelRefund`)
     if (refundCancelResponse && refundCancelResponse.data && refundCancelResponse.data.completionUrl) {
       const response = await axios.get(`/api/payments/${this.props.refund.paymentReference}/agency`)
       window.location.href = response.data.cancellationURL
     }
   }

   refundSuccessRedirect = async () => {
     const response = await axios.get(`/api/payments/${this.props.refund.paymentReference}/agency`)
     window.location.href = `${response.data.refundURL}`
   }

  refundCancelRedirect = async () => {
    const response = await axios.get(`/api/payments/${this.props.refund.paymentReference}/agency`)
    window.location.href = `${response.data.cancellationURL}`
  }

  componentWillUnmount() {
    if (this.statusUpdateProvider != null) {
      this.statusUpdateProvider.stop()
    }
  }

  render() {
    const {
      refundStatus,
      component,
    } = this.state

    return (
      <div className='mt-16 w-2/3 self-center flex-grow'>
        <div className='flex flex-grow'>
          <div className="mx-16 mt-8 flex-grow">
            <div className='border-b border-snsw-gray-6'>
              <div className='text-2xl font-bold pb-3'>Refund</div>
            </div>
            <div key='refundform'>
              <div
                        className="processing-summary-section flex flex-col bg-snsw-gray-7 lg:border-l-4 lg:border-snsw-gray-11 p-4 mt-8 mb-4">
                <h4 className='font-bold p-3'>Processing refund</h4>
                <div>
                  <div>
                    <div className="flex gap-3 flex-wrap ml-6">
                      <div>Payment Reference:</div>
                      <div id="paymentReference">{this.props.refund.paymentReference}</div>
                    </div>
                    <div className="flex gap-3 flex-wrap ml-6">
                      <div>Refund reference:</div>
                      <div id="refundReference">{this.props.refund.refundReference}</div>
                    </div>
                    <div className="flex gap-3 flex-wrap ml-6">
                      <div>Refund Amount:</div>
                      <div id="refundAmount">{StringUtil.formattedAmount(this.props.refund.amount)}</div>
                    </div>
                  </div>
                </div>
                {(refundStatus === 'REQUESTED') && (
                  <div>
                    <button
                                 className='refund-button mx-6 mt-10 mb-4 rounded-md bg-primary px-6 py-2 border-2 border-snsw-gray-5
                                 font-bold text-white content-around'
                                 onClick={this.processRefund.bind(this)}>
                                 Process Refund
                    </button>
                    <button
                                 className='cancel-refund-button mx-6 mt-10 mb-4 rounded-md bg-snsw-gray-2 px-6 py-2 border-2 border-snsw-gray-5
                               font-bold text-snsw-blue-2 content-around'
                                 onClick={this.cancelRefundPayment.bind(this)}>
                                 Cancel Refund
                    </button>
                  </div>
                )}
                {(refundStatus != null && refundStatus !== 'REQUESTED' && refundStatus !== 'CANCELLED') && (
                  <div>
                    {(component !== null) && (
                      <div className='ml-8'>
                        <RefundCardStatusMessage amount={component.amount}
                                                 cardType={component.cardType}
                                                 cardEndDigits={component.cardEndDigits}
                                                 cardRefundStatus={component.refundStatus}
                        />
                      </div>
                    )}
                    {(component === null) && (
                      <div className='ml-8'>
                        <RefundCardStatusMessage amount={this.props.refund.amount}
                                                 cardType={null}
                                                 cardEndDigits={null}
                                                 cardRefundStatus={refundStatus}
                        />
                      </div>
                    )}
                  </div>
                )}
                {(refundStatus === 'APPLIED') && (
                  this.refundSuccessRedirect(this)
                )}
                {(refundStatus === 'CANCELLED') && (
                  this.refundCancelRedirect(this)
                )}
                {(refundStatus === 'FAILED') && (
                  <div>
                    <button
                                 className='retry ml-4 mt-10 mb-4 rounded-md bg-primary px-6 py-2 font-bold text-white text-center'
                                 onClick={this.processRefund.bind(this)}>
                                 Retry
                    </button>
                    <button
                                 className='cancel-refund-button mx-6 mt-10 mb-4 rounded-md bg-snsw-gray-2 px-6 py-2 border-2 border-snsw-gray-5
                               font-bold text-snsw-blue-2 content-around'
                                 onClick={this.cancelRefundPayment.bind(this)}>
                                 Cancel Refund
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

RefundForm.propTypes = {
  refund: PropTypes.object.isRequired,
  csrId: PropTypes.string.isRequired,
  processRefundCallback: PropTypes.func.isRequired,
  cancelRefundCallback: PropTypes.func.isRequired,
}