import React, {useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import {StringUtil} from '../StringUtil'
import debounce from 'debounce'
import redCross from '../../../assets/images/processing-summary/red-cross.svg'

function convertDollarStringToNumber(amountStr, roundingDisabled) {
  if (amountStr.toString().indexOf('$') > -1) {
    amountStr = amountStr.substr(1)
  }
  let amount = Number(amountStr)
  if (isNaN(amount)) {
    amount = 0
  }
  return (roundingDisabled === undefined || roundingDisabled)
    ? amount : nearestFiveCents(amount)
}

function nearestFiveCents(amount) {
  const FIVE_CENTS = 0.05
  const nearest = Math.round(amount / FIVE_CENTS) * FIVE_CENTS
  return Number(Number.parseFloat(nearest).toFixed(2))
}

export function PaymentAmount(props) {
  const {fieldDisabled, amount, roundingDisabled} = props
  const initialAmount = StringUtil.formattedAmount(amount)
  const [inputAmount, setInputAmount] = useState(initialAmount)
  useEffect(() => {
    setInputAmount(initialAmount)
  }, [amount, initialAmount])

  const propsAmountChange = props.amountChange
  const amountChange = useMemo(() => debounce(
    (amountStr) => {
      const amount = convertDollarStringToNumber(amountStr, roundingDisabled)
      propsAmountChange(amount)
    },
    1000),
  [propsAmountChange, roundingDisabled],
  )

  const onAmountChange = (value) => {
    setInputAmount(value)
    amountChange(value)
  }

  const backgroundColor = fieldDisabled === true ? 'bg-snsw-gray-1' : ''
  return (
    <div id={props.id} className='flex mt-8 ml-8 mr-8'>
      <label className='w-1/2 self-center text-lg'>{props.label}</label>
      <input
        className={`payment-amount w-1/2 text-right p-2 ${backgroundColor} border-2 border-snsw-gray-4 rounded text-sm`}
        disabled={fieldDisabled || false}
        onChange={event => onAmountChange(event.target.value)}
        value={inputAmount}
      />
    </div>
  )
}

PaymentAmount.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  fieldDisabled: PropTypes.bool,
  amountChange: PropTypes.func,
  roundingDisabled: PropTypes.bool,
}

export function ValidatablePaymentAmount(
  {id, errorMessage, label, amount, amountChange, fieldDisabled, errorId, roundingDisabled}) {
  return (
    <div id={id}>
      <PaymentAmount label={label} amount={amount} amountChange={amountChange} roundingDisabled={roundingDisabled} fieldDisabled={fieldDisabled}/>
      {errorMessage && (
        <div className='flex'>
          <div className='w-1/2'>&nbsp;</div>
          <div className='w-1/2 flex pr-8'>
            <img className="w-auto h-4 mt-1 mr-2 " src={redCross} alt='validation error'/>
            <p id={errorId} className='text-sm text-red-700 '>
              {errorMessage}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

ValidatablePaymentAmount.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  amountChange: PropTypes.func,
  fieldDisabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorId: PropTypes.string.isRequired,
  roundingDisabled: PropTypes.bool,
}
