import {
  CHANGE_PAYMENT_METHOD,
  SET_MONEY_ORDER_AMOUNT,
  SET_MONEY_ORDER_ISSUED_DATE,
  SET_MONEY_ORDER_NUMBER,
  SET_MONEY_ORDER_UNDERPAYMENT,
} from '../Actions'
import * as yup from 'yup'

const yyyyMMDD = /^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/

yup.setLocale({
  mixed: {
    notType: ({ type}) => {
      return `Must be a ${type}`
    },
  },
})

export const MoneyOrderStateSchema = yup.object().shape({
  orderNumber: yup.string()
    .required('Money Order Number is mandatory')
    .trim('Money Order Number is mandatory')
    .min(1, 'Money Order Number is mandatory'),
  issuedDate: yup.string()
    .required('Issued Date is mandatory and should not be in the future')
    .matches(yyyyMMDD, 'Issued Date must be a valid date in the format YYYY-MM-DD')
    .test('is not in the future', 'Issued Date is mandatory and should not be in the future', (date) => {
      const today = new Date()
      const issuedDate = new Date(date)
      issuedDate.setHours(0, 0, 0, 0)
      return issuedDate <= today
    }),
  amount: yup.number()
    .positive('Money Order Amount should be greater than zero')
    .required('Money Order Amount is mandatory'),
  underpayment: yup.bool()
    .equals([false], 'Money Order Amount is mandatory and should be at least the amount to be charged'),
})

export const moneyOrderStateReducer = (moneyOrderState = undefined, action) => {
  switch (action.type) {
    case SET_MONEY_ORDER_NUMBER:
      return {
        ...moneyOrderState,
        orderNumber: action.value,
      }
    case SET_MONEY_ORDER_ISSUED_DATE:
      return {
        ...moneyOrderState,
        issuedDate: action.value,
      }
    case SET_MONEY_ORDER_AMOUNT:
      return {
        ...moneyOrderState,
        amount: action.value,
      }
    case SET_MONEY_ORDER_UNDERPAYMENT:
      return {
        ...moneyOrderState,
        underpayment: action.value,
      }
    case CHANGE_PAYMENT_METHOD:
      if (action.paymentMethod === 'MONEY_ORDER') {
        return blankMoneyOrderState
      } else {
        return undefined
      }
    default:
      return moneyOrderState
  }
}

export const blankMoneyOrderState = {
  issuedDate: undefined,
  institution: 'Australia Post',
  orderNumber: undefined,
  amount: undefined,
  underpayment: undefined,
}
