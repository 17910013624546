export default function roundedAmountIfRoundingUp(unroundedAmount) {
  const FIVE_CENTS = 0.05
  const roundedAmount = Math.round(unroundedAmount / FIVE_CENTS) * FIVE_CENTS
  const roundedAmountFormatted = Number(Number.parseFloat(roundedAmount).toFixed(2))

  // inverse of FIVE_CENTS
  const CEIL_FACTOR = 20
  const ceilAmount = Math.ceil(unroundedAmount * CEIL_FACTOR) / CEIL_FACTOR
  const ceilAmountFormatted = Number(Number.parseFloat(ceilAmount).toFixed(2))

  if (ceilAmountFormatted > roundedAmountFormatted) {
    return unroundedAmount
  }
  return roundedAmountFormatted
}
