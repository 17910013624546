import React from 'react'
import PropTypes from 'prop-types'

CardFailureRecoveryOptions.propTypes = {
  useAnotherPaymentMethodCallback: PropTypes.func.isRequired,
  retryCardCallback: PropTypes.func.isRequired,
  cancelPaymentCallback: PropTypes.func.isRequired,
}

export function CardFailureRecoveryOptions(props) {
  const {useAnotherPaymentMethodCallback, retryCardCallback, cancelPaymentCallback} = props
  const buttonEnabled = String(process.env.REACT_APP_ENABLE_CHOOSE_ANOTHER_PAYMENT_METHOD)==='true'

  return (
    <div>
      <button onClick={cancelPaymentCallback}
              className='cancel-button mx-6 mt-10 mb-4 rounded-md bg-snsw-gray-2 px-6 py-2 border-2 border-snsw-gray-5
                           font-bold text-snsw-blue-2 content-around'>
        Cancel Payment
      </button>
      <button
        className='try-different-card ml-4 mr-2 mt-10 mb-4 rounded-md bg-primary px-6 py-2 font-bold text-white text-center'
        onClick={retryCardCallback}>
        Retry Payment
      </button>
      { buttonEnabled && (<button
        className="use-another-payment-method mt-10 mb-4 underline background-transparent
        font-bold text-snsw-blue-2 outline-none focus:outline-none text-center"
        onClick={useAnotherPaymentMethodCallback}>
        Use another payment method
      </button>) }
    </div>
  )
}


