import React from 'react'
import PropTypes from 'prop-types'
import {PaymentStatusMessage} from '../PaymentStatusMessage'
import spinner from '../../../assets/images/processing-summary/grey-spinner.svg'

PaidPaymentStatus.propTypes = {
  agencyCode: PropTypes.string,
}

export function PaidPaymentStatus(props) {

  let finalisingMsg = 'Finalising payment with agency'
  if(['RMS', 'SNSW_RMS'].includes(props.agencyCode.toUpperCase())) {
    finalisingMsg += '. Please press F10 in Drives'
  }

  return (
    <div>
      <PaymentStatusMessage image={spinner}
                            className='sending-to-agency'
                            message={finalisingMsg}/>
    </div>
  )
}


