import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {CardStatusMessage} from './card/CardStatusMessage'
import {PaymentStatusMessage} from './PaymentStatusMessage'
import spinner from '../../assets/images/processing-summary/grey-spinner.svg'
import redCross from '../../assets/images/processing-summary/red-cross.svg'
import greenTick from '../../assets/images/processing-summary/green-tick.svg'
import {CollectedCashStatusMessage} from './cash/CashStatusMessage'
import {CollectedChequeStatusMessage} from './cheque/ChequeStatusMessage'
import {CollectedMoneyOrderStatusMessage} from './moneyOrder/MoneyOrderStatusMessage'
import {PaidPaymentStatus} from './paymentStatus/PaidPaymentStatus'
import {VoidedStatusMessage} from './paymentStatus/VoidedStatusMessage'
import {VoidCardStatusMessage} from './card/VoidCardStatusMessage'
import {RESET_PAYMENT_PROCESS} from '../payment-form/collect-payment/options/Actions'
import VoidOverrideModal from './override/VoidOverrideModal'
import {AppStateContext} from '../App.state'
import PollingStatusProvider from './PollingStatusProvider'
import StreamingStatusProvider from './StreamingStatusProvider'

export class PaymentStatus extends React.Component {
  pollInterval
  statusUpdateProvider

  state = {
    uiStatus: 'IN_PROGRESS',
    paymentStatus: null,
    components: [],
    lastPaymentStatus: null,
    showModal: false,
    streaming: false,
  }

  handleClose = () => this.setState({showModal: false});

  handleShow = () => this.setState({showModal: true});

  async componentDidMount() {

    await this.getStreamingFeatureFlag(this.props.csrId)

    if (this.state.streaming) {
      this.statusUpdateProvider = new StreamingStatusProvider(this.props.paymentReference)
    } else {
      this.statusUpdateProvider = new PollingStatusProvider(this.props.paymentReference)
    }

    this.statusUpdateProvider.onStatusUpdate(({paymentStatus, components, needsOverride}) => {
      this.setState({paymentStatus, components, needsOverride})
    })

    this.statusUpdateProvider.onCompletion((completionUrl) => {
      setTimeout(() => this.completionSuccessRedirect(completionUrl), 1000)
    })
  }

  getStreamingFeatureFlag = async (csrId) => {
    try {
      const streamingFlag = await axios.get(
        `${process.env.REACT_APP_CPP_OTC_BFF_BASE_URL || ''}/api/featureflags/status.streaming/${csrId}`)
      this.setState({streaming: streamingFlag.data.value === 'true'})
    } catch (error) {
      console.log(error)
      this.setState({streaming: false})
    }
  }

  async cancelVoidPayment() {
    const unpayCancelResponse = await axios.post(`/api/payments/${this.props.paymentReference}/un-pay-cancel`)
    if (unpayCancelResponse && unpayCancelResponse.data && unpayCancelResponse.data.completionUrl) {
      window.location.href = unpayCancelResponse.data.completionUrl
    }

  }

  voidPaymentCallback() {
    const paymentStatus = this.state.paymentStatus
    this.setState({
      uiStatus: 'VOID_REQUESTED',
      lastPaymentStatus: paymentStatus,
    })
    axios.post(`/api/payments/${this.props.paymentReference}/void`, {reason: 'AGENCY_REQUESTED'})
    this.setState(
      {showModal: false}
    )
  }

  voidPayment() {
    if (this.state.needsOverride === true) {
      this.handleShow()
    } else {
      this.voidPaymentCallback()
    }
  }

  cancelPayment = async () => {
    const {paymentReference} = this.props
    this.setState({
      uiStatus: 'VOID_REQUESTED',
    })
    await axios.post(`${process.env.REACT_APP_CPP_OTC_BFF_URL || ''}/api/payments/${paymentReference}/cancel`,
      {reason: 'USER_CANCELLED'})

  }

  voidSuccessRedirect = async () => {
    const response = await axios.get(`/api/payments/${this.props.paymentReference}/agency`)
    window.location.href = `${response.data.voidURL}`
  }

  completionSuccessRedirect = (completionUrl) => {
    window.location.href = completionUrl
  }

  retryPaymentNotification() {
    this.setState({
      uiStatus: 'RETRY_REQUESTED',
    })
    axios.post(`/api/payments/notification/${this.props.paymentReference}/retry`)
      .finally(() => {
        return this.statusUpdateProvider.restart()
      })
      .finally(() => {
        this.setState({
          uiStatus: 'IN_PROGRESS',
        })
      })
  }

  retryCard(tryDifferentCardCallBack) {
    this.setState({
      paymentStatus: null,
      components: null,
    })
    tryDifferentCardCallBack()
    this.statusUpdateProvider.restart()
  }

  componentWillUnmount() {
    this.statusUpdateProvider.stop()
  }

  render() {
    const {
      paymentStatus,
      uiStatus,
      lastPaymentStatus,
      components,
    } = this.state
    const {dispatch} = this.context

    const {agencyCode} = this.props

    const changePaymentMethodOrCancelledPayment = (this.state.paymentStatus === 'CHANGE_PAYMENT_VOIDED'
      || this.state.paymentStatus === 'CANCELLED' || this.state.paymentStatus === 'CANCEL_REQUESTED')
    const useAnotherPaymentMethod = () => {
      if (changePaymentMethodOrCancelledPayment) {
        dispatch({type: RESET_PAYMENT_PROCESS})
      }
      this.props.useAnotherPaymentMethodCallback(changePaymentMethodOrCancelledPayment, dispatch)
    }

    const cardComponents = !components ? [<div key={0}/>] : components && components.map(
      (component, i) => {
        return (
          <div className='ml-8' key={'card-' + i}>
            {component.paymentMethod === 'CARD' && (
              <CardStatusMessage
                amount={component.amount}
                cardType={component.cardType}
                cardEndDigits={component.cardEndDigits}
                useAnotherPaymentMethodCallback={useAnotherPaymentMethod}
                cardPaymentStatus={component.paymentMethodStatus}
                retryCardCallback={() => this.retryCard(this.props.tryDifferentCardCallBack)}
                cancelPaymentCallback={() => this.cancelPayment()}
                hideVoidOptions={changePaymentMethodOrCancelledPayment}
              />
            )}
          </div>
        )
      })

    const bankableComponents = !components ? [<div key={1}/>] : components.map(
      (component, i) => {
        return (
          <div className='ml-8' key={'bankable-' + i}>
            {component.paymentMethod === 'MONEY_ORDER' && (
              <CollectedMoneyOrderStatusMessage
                amount={component.amount}
                moneyOrderNumber={component.moneyOrderNumber}/>
            )}

            {component.paymentMethod === 'CASH' && (
              <CollectedCashStatusMessage
                roundedAmount={component.amount}/>
            )}

            {component.paymentMethod === 'CHEQUE' && (
              <CollectedChequeStatusMessage
                amount={component.amount}
                chequeNumber={component.chequeNumber}/>
            )}
          </div>
        )
      })

    function componentsContainBankables(components) {
      let bankableItemsCount = 0
      components.forEach((item) => {
        if ((item.paymentMethod === 'CASH'
            || item.paymentMethod === 'CHEQUE'
            || item.paymentMethod === 'MONEY_ORDER')
            && item.paymentMethod !== 'CARD') {
          bankableItemsCount++
        }
      },
      )
      return (bankableItemsCount > 0 && bankableItemsCount === components.length)
    }

    function hasStartedVoidFlow() {
      return (uiStatus === 'VOID_REQUESTED'
        || paymentStatus === 'VOID_REQUESTED'
        || paymentStatus === 'VOIDED')
    }

    return (
      <>
        <div className="mx-16 mt-8 flex-grow">
          <div className='border-b border-snsw-gray-6'>
            <div className='text-2xl font-bold pb-3'>Processing payment</div>
          </div>

          {paymentStatus === 'CANCELLED' && (
            <div
              className="processing-summary-section flex-col bg-snsw-gray-7 lg:border-l-4 lg:border-snsw-gray-11 p-4 mt-8 mb-4">
              <PaymentStatusMessage image={greenTick} className='payment-cancelled'
                                    message='Payment has been cancelled'/>

              <button onClick={this.props.cancelPaymentSuccessRedirect.bind(this)}
                      className='return-button mx-6 mt-10 mb-4 rounded-md bg-snsw-gray-2 px-6 py-2 border-2 border-snsw-gray-5
                           font-bold text-snsw-blue-2 content-around'>
                Return
              </button>
            </div>
          )}

          {paymentStatus === 'CHANGE_PAYMENT_VOIDED' && (
            <div
              className="processing-summary-section flex flex-col bg-snsw-gray-7 lg:border-l-4 lg:border-snsw-gray-11 p-4 mt-8 mb-4">
              <PaymentStatusMessage image={greenTick} className='payment-voided'
                                    message='Payment has been reversed'/>
              <div id='components'>
                {cardComponents}
              </div>
              <button onClick={useAnotherPaymentMethod}
                      className='return-button mx-6 mt-10 mb-4 rounded-md bg-snsw-gray-2 px-6 py-2 border-2 border-snsw-gray-5
                           font-bold text-snsw-blue-2 content-around'>
                Back To Payment
              </button>
            </div>
          )}

          {paymentStatus !== 'CANCELLED' && paymentStatus !== 'CHANGE_PAYMENT_VOIDED' && (
            <div key='cancelled'>

              <div
                className="processing-summary-section flex flex-col bg-snsw-gray-7 lg:border-l-4 lg:border-snsw-gray-11 p-4 mt-8 mb-4">
                <h4 className='font-bold p-3'>Please wait - do not close the browser</h4>

                {!paymentStatus && (
                  <PaymentStatusMessage image={spinner} className='processing-payment'
                                        message='Processing...'/>
                )}

                {paymentStatus && paymentStatus === 'IN_PROGRESS' && (
                  <div>
                    <PaymentStatusMessage image={spinner} className='processing-payment'
                                          message='Processing payment'/>
                    <div id='components'>
                      {cardComponents}
                    </div>
                  </div>
                )}

                {paymentStatus && paymentStatus === 'CANCEL_REQUESTED' && (
                  <div>
                    <PaymentStatusMessage image={spinner} className='cancelling-payment'
                                          message='Cancelling payment'/>
                  </div>
                )}

                {/*{paymentStatus && paymentStatus === 'PAY_FAILED' && (
                <div>
                  <PaymentStatusMessage image={redCross} className='processing-payment'
                                        message='Processing failed'/>
                  <div id='components'>
                    {cardComponents}
                  </div>
                  <button onClick={this.props.cancelPaymentCallback.bind(this)}
                          className='cancel-button mx-6 mt-10 mb-4 rounded-md bg-snsw-gray-2 px-6 py-2 border-2 border-snsw-gray-5
                           font-bold text-snsw-blue-2 content-around'>
                    Cancel Payment
                  </button>
                </div>
              )}*/}

                {paymentStatus && (paymentStatus !== 'IN_PROGRESS')
                && (paymentStatus !== 'PAY_FAILED')
                && (paymentStatus !== 'CHANGE_PAYMENT_VOIDED')
                && (paymentStatus !== 'CANCEL_REQUESTED')
                && (
                  <div>
                    <PaymentStatusMessage image={greenTick} className='payment-processed'
                                          message='Payment processed'/>
                    {paymentStatus && !hasStartedVoidFlow() && <div id='components'>
                      {cardComponents.concat(bankableComponents)}
                    </div>}
                  </div>
                )}

                {paymentStatus === 'PAID' && !hasStartedVoidFlow() && <PaidPaymentStatus agencyCode={agencyCode}/>}

                {paymentStatus === 'COMPLETED' && !hasStartedVoidFlow() && (
                  <div>
                    <PaymentStatusMessage image={greenTick} className='payment-finalised'
                                          message='Payment finalised with agency'/>
                    <PaymentStatusMessage image={spinner} className='redirect-to-agency'
                                          message='Redirecting...'/>
                  </div>
                )}

                {(paymentStatus === 'AGENCY_NOTIFICATION_FAILED')
                && uiStatus === 'IN_PROGRESS' && (
                  <div>
                    <PaymentStatusMessage image={redCross} className='agency-notification-failed'
                                          message='Agency notification failed, do you want to void or retry?'/>
                    <button
                      className='void-button mx-6 mt-10 mb-4 rounded-md bg-snsw-gray-2 px-6 py-2 border-2 border-snsw-gray-5
                           font-bold text-snsw-blue-2 content-around'
                      onClick={this.voidPayment.bind(this)}>
                      Void Payment
                    </button>
                    <button
                      className='retry-button mx-6 mt-10 mb-4 rounded-md bg-snsw-gray-2 px-6 py-2 border-2 border-snsw-gray-5
                           font-bold text-snsw-blue-2 content-around'
                      onClick={this.retryPaymentNotification.bind(this)}>
                      Retry
                    </button>
                  </div>
                )}

                {(paymentStatus === 'UNPAY_REQUESTED')
                && uiStatus === 'IN_PROGRESS' && (
                  <div>
                    <PaymentStatusMessage image={greenTick} className='agency-requested-unpay'
                                          message='Agency requested to UnPay'/>
                    <button
                      className='void-button mx-6 mt-10 mb-4 rounded-md bg-snsw-gray-2 px-6 py-2 border-2 border-snsw-gray-5
                           font-bold text-snsw-blue-2 content-around'
                      onClick={this.voidPayment.bind(this)}>
                      Void Payment
                    </button>
                    <button
                      className='cancel-void-button mx-6 mt-10 mb-4 rounded-md bg-snsw-gray-2 px-6 py-2 border-2 border-snsw-gray-5
                           font-bold text-snsw-blue-2 content-around'
                      onClick={this.cancelVoidPayment.bind(this)}
                    >
                      Cancel Void Payment
                    </button>
                  </div>
                )}

                {(paymentStatus === 'VOIDED' || uiStatus === 'VOID_REQUESTED')
                && (lastPaymentStatus && lastPaymentStatus === 'AGENCY_NOTIFICATION_FAILED')
                && (
                  <div>
                    <PaymentStatusMessage image={redCross} className='agency-notification-failed'
                                          message='Agency notification failed'/>
                  </div>
                )}

                {(paymentStatus === 'VOIDED' || uiStatus === 'VOID_REQUESTED')
                && (lastPaymentStatus && lastPaymentStatus === 'UNPAY_REQUESTED')
                && (
                  <div>
                    <PaymentStatusMessage image={greenTick} className='agency-requested-unpay'
                                          message='Agency requested to UnPay'/>
                  </div>
                )}

                {(paymentStatus === 'AGENCY_NOTIFICATION_FAILED'
                  || paymentStatus === 'UNPAY_REQUESTED')
                && (
                  <div>
                    {(uiStatus === 'VOID_REQUESTED' && componentsContainBankables(components)) &&
                    <PaymentStatusMessage image={spinner} className='voiding-payment'
                                          message='Voiding payment'/>
                    }
                    {uiStatus === 'RETRY_REQUESTED' &&
                    <PaymentStatusMessage image={spinner} className='sending-to-agency'
                                          message='Sending to agency'/>
                    }
                  </div>
                )}

                {(paymentStatus === 'AGENCY_NOTIFICATION_FAILED'
                  || paymentStatus === 'UNPAY_REQUESTED'
                  || paymentStatus === 'VOIDED'
                  || paymentStatus === 'CANCEL_REQUESTED')
                && components && components.map(
                  (component,i) => {
                    return (<div className='ml-8' key={component.paymentMethod + i}>
                      {component.paymentMethod === 'CARD' && component.cardType &&
                      (<VoidCardStatusMessage amount={component.amount}
                                              cardType={component.cardType}
                                              cardEndDigits={component.cardEndDigits}
                                              cardPaymentStatus={component.paymentMethodStatus}
                                              retryCardCallback={() => this.voidPayment()}
                      />)
                      }
                    </div>)
                  },
                )}

                {paymentStatus === 'VOIDED' && components && components.map(
                  component => {
                    return (
                      <React.Fragment key={component}>
                        <VoidedStatusMessage component={component}/>
                      </React.Fragment>
                    )
                  })
                }

                {paymentStatus === 'VOIDED' && (
                  <div>
                    <PaymentStatusMessage image={greenTick} className='payment-voided'
                                          message='Payment voided'/>
                    <button onClick={this.voidSuccessRedirect.bind(this)}
                            className='cancel-button mx-6 mt-10 mb-4 rounded-md bg-snsw-gray-2 px-6 py-2 border-2 border-snsw-gray-5
                           font-bold text-snsw-blue-2 content-around'>
                      Return
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}

        </div>
        <VoidOverrideModal
          status={paymentStatus}
          paymentReference={this.props.paymentReference}
          showModal={this.state.showModal}
          closeModal={this.handleClose.bind(this)}
        />
      </>

    )
  }
}

PaymentStatus.contextType = AppStateContext

PaymentStatus.propTypes = {
  paymentReference: PropTypes.string.isRequired,
  csrId: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  agencyCode: PropTypes.string.isRequired,
  roundedAmount: PropTypes.number.isRequired,
  cancelPaymentSuccessRedirect: PropTypes.func.isRequired,
  useAnotherPaymentMethodCallback: PropTypes.func,
  tryDifferentCardCallBack: PropTypes.func,
  createEventSource: PropTypes.func,
}
