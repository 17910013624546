export const calculateTotalPaymentAmount = (paymentComponents) => {
  if (paymentComponents === undefined) {
    return 0
  }

  const initValue = 0
  return paymentComponents.reduce((accumulator, c) => {
    return accumulator + (c.amount || 0)
  }, initValue)
}
