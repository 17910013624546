export const reasonCodes = [
  {
    code: 1,
    text: 'DRIVES invoice not required but unable to be deleted',
    desc: 'For example, knowledge test or driver test booking, or licence renewal.\nNote: DHD log number will not be provided for a CSR error',
    logRequired : false,
    logPlaceholder: 'SNSW Number required',
    logType: 'SNSW',
    limit: 1000},
  {
    code: 2,
    text: 'DRIVES Help Desk approved',
    desc: 'For all underpayments approved by DRIVES Help Desk\n\nFor example, NHVAS accredited vehicle inspection, carers registration',
    logRequired : true,
    logPlaceholder: 'DHD Number required',
    logType: 'DRIVES',
    limit: 1000},
  {
    code: 3,
    text: 'CPP to DRIVES error',
    desc: 'No actual cash shortage. A successful payment was taken but the invoice cannot be finalised in DRIVES. Invoice already paid in PSP error. Invoice will need to be void in DRIVES and reprocessed. Ensure you note the original invoice number to confirm customer payment was successful in CPP.\nNote: Not to be used for CSR errors.',
    logRequired : true,
    logPlaceholder: 'SNSW Number required',
    logType: 'SNSW',
    limit: 10000},
  {
    code: 4,
    text: 'MISSED F10 – CSR ERROR',
    desc: 'No actual cash shortage. An operator forgot to finalise the invoice from previous day (F10) in DRIVES and needs to fulfil the entitlement.',
    logRequired : true,
    logPlaceholder: 'SNSW Number required',
    logType: 'SNSW',
    limit: 10000},
  {
    code: 5,
    text: 'Underpayment of up to $6.00 for bank cheques and mail payments only',
    desc: '',
    logRequired : false,
    logPlaceholder: '',
    logType: '',
    limit: 6},
  {
    code: 6,
    text: 'DRIVES fee discrepancy',
    desc: 'Transaction incorrectly processed previously with a CSR error, but the fees are now higher.',
    logRequired : true,
    logPlaceholder: 'SNSW Number required',
    logType: 'SNSW',
    limit: 100},
  {
    code: 7,
    text: 'Manager waiver as per Manager’s discretion',
    desc: '',
    logRequired: false,
    logPlaceholder: '',
    logType: '',
    limit: 500},
  {
    code: 8,
    text: 'Disaster/Privacy waivers',
    desc: 'Emergencies such as bushfires, floods, or drought, and privacy breaches.',
    logRequired : true,
    logPlaceholder: 'FIN Number required',
    logType: 'FIN',
    limit: 1000}]