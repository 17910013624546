import PropTypes from 'prop-types'
import {PaymentAmount, ValidatablePaymentAmount} from '../../PaymentAmount'
import React, {useContext, useEffect, useState} from 'react'
import {AppStateContext} from '../../../../App.state'
import {
  SET_CHEQUE_ACCOUNT,
  SET_CHEQUE_AMOUNT,
  SET_CHEQUE_BSB,
  SET_CHEQUE_NUMBER,
  SET_CHEQUE_TYPE,
  SET_CHEQUE_UNDERPAYMENT,
} from '../Actions'
import {ValidatableField} from '../ValidatableField'
import {PaymentFormStateValidator} from '../../../PaymentFormStateValidator'

Cheque.propTypes = {
  splitPaymentEnabled: PropTypes.bool.isRequired,
  amount: PropTypes.number.isRequired,
}

export function Cheque(props) {
  const {appState, dispatch} = useContext(AppStateContext)
  const [chequeInitiallyLoaded, setChequeInitiallyLoaded] = useState(false)
  useEffect(() => {
    setChequeInitiallyLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {chequePayment: {chequeNumber, account, bsb}} = appState
  const paymentFormStateValidator = PaymentFormStateValidator('CHEQUE')
  const chequeNumberErrorMessage = paymentFormStateValidator.validateField('chequeNumber', appState.chequePayment).errorMessage
  const accountErrorMessage = paymentFormStateValidator.validateField('account', appState.chequePayment).errorMessage
  const bsbErrorMessage = paymentFormStateValidator.validateField('bsb', appState.chequePayment).errorMessage
  const underpaymentErrorMessage = paymentFormStateValidator.validateField('underpayment', appState.chequePayment).errorMessage
  const amountErrorMessage = paymentFormStateValidator.validateField('amount', appState.chequePayment).errorMessage

  function chequeAmountInputChanged(newChequeAmount) {
    setChequeInitiallyLoaded(false)
    dispatch({type: SET_CHEQUE_AMOUNT, value: newChequeAmount})
    if (props.splitPaymentEnabled) {
      dispatch({type: SET_CHEQUE_UNDERPAYMENT, value: false})
    } else {
      dispatch({type: SET_CHEQUE_UNDERPAYMENT, value: newChequeAmount < props.amount})
    }
  }

  return (
    <div id='cheque-section' data-testid='cheque-section' className='border-4 border-snsw-gray-7 m-2 mt-6'>
      {!props.splitPaymentEnabled && <PaymentAmount
        id='cheque-amount-to-be-charged'
        label='Amount to be charged'
        amount={props.amount}
        fieldDisabled={true}/>}
      <ValidatablePaymentAmount
        id='cheque-payment-amount'
        label='Cheque Amount'
        amount={undefined}
        amountChange={chequeAmountInputChanged}
        errorId='cheque-amount-validation-error'
        errorMessage={(!chequeInitiallyLoaded && props.splitPaymentEnabled) ?
          (props.amount > 0 ? amountErrorMessage : '') :
          underpaymentErrorMessage}
        fieldDisabled={props.amount <= 0}
      />
      <div className='flex m-8'>
        <label className='w-1/2 self-center text-lg'>Type</label>
        <input
          id='cheque-type-personal'
          name='cheque-type'
          value='PERSONAL'
          type='radio'
          className='mt-1 mr-2'
          checked={appState.chequePayment.chequeType === 'PERSONAL'}
          onChange={() => dispatch({type: SET_CHEQUE_TYPE, value: 'PERSONAL'})}
        />
        <label htmlFor='cheque-type-personal'>Personal</label>
        <input
          id='cheque-type-bank'
          name='cheque-type'
          value='BANK'
          type='radio'
          className='ml-8 mt-1 mr-2'
          checked={appState.chequePayment.chequeType === 'BANK'}
          onChange={() => dispatch({type: SET_CHEQUE_TYPE, value: 'BANK'})}
        />
        <label htmlFor='cheque-type-bank'>Bank</label>
      </div>

      <ValidatableField
        label='Cheque Number'
        inputId='cheque-number'
        actionType={SET_CHEQUE_NUMBER}
        errorMessage={chequeNumber && chequeNumberErrorMessage}
        value={appState.chequePayment.chequeNumber}
        fieldDisabled={props.amount <= 0}
      />
      <ValidatableField
        label='BSB'
        inputId='cheque-bsb'
        actionType={SET_CHEQUE_BSB}
        errorMessage={bsb && bsbErrorMessage}
        value={appState.chequePayment.bsb}
        fieldDisabled={props.amount <= 0}
      />
      <ValidatableField
        label='Account'
        inputId='cheque-account'
        actionType={SET_CHEQUE_ACCOUNT}
        errorMessage={account && accountErrorMessage}
        value={appState.chequePayment.account}
        fieldDisabled={props.amount <= 0}
      />
    </div>
  )
}

