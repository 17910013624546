import React, {useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {PaymentDetails} from './collect-payment/PaymentDetails'
import {PaymentOptions} from './collect-payment/options/PaymentOptions'
import {Card} from './collect-payment/options/card/Card'
import {Cash} from './collect-payment/options/cash/Cash'
import {Cheque} from './collect-payment/options/cheque/Cheque'
import MoneyOrder from './collect-payment/options/moneyOrder/MoneyOrder'
import {ProcessPayment} from './process-payment/ProcessPayment'
import {AppStateContext} from '../App.state'
import {validatePayment} from './validatePayment'
import axios from 'axios'
import {
  ADD_TO_TOTAL,
  RESET_PAYMENT_FORM,
  SET_CARD_AMOUNT,
  SET_CASH_AMOUNT,
  SET_CASH_TENDERED_AMOUNT,
  SET_CHEQUE_AMOUNT,
  SET_CHEQUE_UNDERPAYMENT,
  SET_MONEY_ORDER_AMOUNT,
  SET_MONEY_ORDER_UNDERPAYMENT,
} from './collect-payment/options/Actions'
import {calculateTotalPaymentAmount} from './calculateTotalPaymentAmount'
import roundedAmountIfRoundingUp from './roundedAmountIfRoundingUp'

export function PaymentForm(props) {
  const {appState, dispatch} = useContext(AppStateContext)

  const [paymentMethod, setPaymentMethod] = useState('')
  const [splitPaymentEnabled, setSplitPaymentEnabled] = useState(props.splitPaymentEnabled || false)
  const [underpaymentDisabled, setUnderpaymentDisabled] = useState(false)
  const [enableUnderpayment, setEnableUnderpayment] = useState(false)
  const [wasUnderpaymentEnabled, setWasUnderpaymentEnabled] = useState(false)

  const [acceptedPaymentMethods, setAcceptedPaymentMethods] = useState([])
  const [surchargeOverrides, setSurchargeOverrides] = useState([])

  const paymentMethodUpdated = (method) => {
    setPaymentMethod(method)
  }

  const splitPaymentOptionUpdated = (option) => {
    setSplitPaymentEnabled(option)
    if (acceptedPaymentMethods.length > 0) {
      paymentMethodUpdated(acceptedPaymentMethods.find(paymentMethod => paymentMethod === 'CARD')
          || acceptedPaymentMethods[0])
    }
    dispatch({type: SET_CARD_AMOUNT, value: props.payment.amount})
  }

  function preparePayment() {
    if (splitPaymentEnabled) {
      return appState.paymentComponents

    } else {

      if (!validatePayment(paymentMethod, appState)) {
        return []
      }

      switch (paymentMethod) {
        case 'CHEQUE':
          return [{
            componentType: 'CHEQUE',
            ...appState.chequePayment,
            overpayment: undefined,
          }]
        case 'CASH':
          return [{
            componentType: 'CASH',
            ...appState.cashPayment,
            amount: props.payment.amount,
          }]
        case 'MONEY_ORDER':
          return [{
            componentType: 'MONEY_ORDER',
            ...appState.moneyOrderPayment,
            overpayment: undefined,
          }]
        case 'CARD':
          return [{
            componentType: 'CARD',
            ...appState.cardPayment,
          }]
        default:
          return props.initialPaymentComponents
      }
    }
  }

  function onProcessPaymentClicked() {
    const validatedPaymentComponents = preparePayment()
    return props.processPaymentCallback(validatedPaymentComponents)
  }

  function onAddToTotalClicked() {
    let currentSplit
    console.log(paymentMethod)
    if (paymentMethod === 'CARD') currentSplit = appState.cardPayment
    if (paymentMethod === 'CASH') currentSplit = appState.cashPayment
    if (paymentMethod === 'CHEQUE') currentSplit = appState.chequePayment
    if (paymentMethod === 'MONEY_ORDER') currentSplit = appState.moneyOrderPayment
    const paymentToAdd = {
      ...currentSplit,
      componentType: paymentMethod,
    }
    dispatch({type: ADD_TO_TOTAL, value: paymentToAdd})
    dispatch({type: RESET_PAYMENT_FORM, paymentMethod: paymentMethod})

    // Calculate Outstanding Amount
    const outstandingAmount = props.payment.amount - calculateTotalPaymentAmount(appState.paymentComponents) - currentSplit.amount
    console.log(outstandingAmount)
    if (outstandingAmount <= 0) {
      setUnderpaymentDisabled(true)
      setEnableUnderpayment(false)
    } else {
      setUnderpaymentDisabled(false)
    }
  }
  function chequeAmountInputChanged(newChequeAmount) {
    dispatch({type: SET_CHEQUE_AMOUNT, value: newChequeAmount})

    if (splitPaymentEnabled) {
      dispatch({type: SET_CHEQUE_UNDERPAYMENT, value: false})
    } else {
      dispatch({type: SET_CHEQUE_UNDERPAYMENT, value: newChequeAmount < props.payment.amount })
    }
  }

  function moneyOrderAmountInputChanged(newMoneyOrderAmount) {
    dispatch({type: SET_MONEY_ORDER_AMOUNT, value: newMoneyOrderAmount})

    if (splitPaymentEnabled) {
      dispatch({type: SET_MONEY_ORDER_UNDERPAYMENT, value: false})
    } else {
      const isUnderpayment = newMoneyOrderAmount < props.payment.amount
      dispatch({type: SET_MONEY_ORDER_UNDERPAYMENT, value: isUnderpayment})
    }
  }


  function updatePaymentOptionOutstandingAmount(paymentMethod,outstandingAmount){
    switch (paymentMethod) {
      case 'CARD':  dispatch({type: SET_CARD_AMOUNT, value: outstandingAmount})
        break
      case 'CASH':
        dispatch({type: SET_CASH_AMOUNT, value: outstandingAmount})
        dispatch({type: SET_CASH_TENDERED_AMOUNT, value: outstandingAmount})
        break
      case 'CHEQUE':
        chequeAmountInputChanged(outstandingAmount)
        break
      case 'MONEY_ORDER':
        moneyOrderAmountInputChanged(outstandingAmount)
        break
      default:
        break
    }
  }

  function renderAddToTotalButton(paymentMethod) {
    const disableButton = !validatePayment(paymentMethod, appState)
    return (
      <button
        disabled={disableButton}
        className={`add-to-total-button ${disableButton ? 'opacity-50 pointer-events-none select-none' : ''}`}
        onClick={onAddToTotalClicked}>
          Add to Total
      </button>
    )
  }

  function disabledForNonSplitPayment() {
    return !validatePayment(paymentMethod, appState) && !splitPaymentEnabled
  }

  function onChangeUnderpayment(checked) {
    setEnableUnderpayment(checked)
    setWasUnderpaymentEnabled(true)
    splitPaymentOptionUpdated(true)
  }

  const outstandingAmount = Number((props.payment.amount  - calculateTotalPaymentAmount(appState.paymentComponents)).toFixed(2))
  useEffect( () => {
    const getPaymentMethods = async () => {
      const response = await axios.get(`/api/payments/${props.payment.paymentReference}/calling-system-details`)
      const acceptedPaymentMethodsArray = response.data.acceptedPaymentMethods
      const surchargeOverrideMap = response.data.surchargeOverrides
      console.log(acceptedPaymentMethodsArray)
      console.log(surchargeOverrideMap)
      setAcceptedPaymentMethods(acceptedPaymentMethodsArray)
      setSurchargeOverrides(surchargeOverrideMap)
      if (acceptedPaymentMethodsArray.length > 0) {
        paymentMethodUpdated(acceptedPaymentMethodsArray.find(paymentMethod => paymentMethod === 'CARD')
            || acceptedPaymentMethodsArray[0])
      }
    }

    if (acceptedPaymentMethods.length === 0) {
      getPaymentMethods().catch(reason => console.log(`Exception happened: ${reason}`))
    }

    if (splitPaymentEnabled) updatePaymentOptionOutstandingAmount(paymentMethod, outstandingAmount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outstandingAmount])

  const processWasUnderpaymentEnabled = (value) => {
    if (value === undefined) {
      return wasUnderpaymentEnabled
    }
    setUnderpaymentDisabled(value)
    setWasUnderpaymentEnabled(value)
  }
  return (
    <div className='mt-16 w-2/3 self-center flex-grow'>
      <div className='flex flex-grow'>
        <div className='flex flex-col ml-auto w-2/3 pr-20 mb-16'>
          <div>
            <PaymentDetails amount={props.payment.amount}/>
            <div className='border-snsw-gray-6 ml-8 mb-8'>
              {props.underpaymentFeatureEnabled !== undefined &&
                  props.underpaymentFeatureEnabled === true &&
                  acceptedPaymentMethods.includes('UNDERPAYMENT') &&
                  <div className='self-start mt-2'>
                    <div>
                      <input disabled={underpaymentDisabled} className="mr-2 underpayment-checkbox" id="underpayment"
                             data-testid="underpayment"
                             type="checkbox"
                             aria-checked={enableUnderpayment}
                             onChange={({target: {checked}}) => onChangeUnderpayment(checked)}/>
                      <label htmlFor="underpayment" className="font-bold">Enable underpayment</label>
                    </div>
                  </div>
              }
            </div>
            <PaymentOptions
              acceptedPaymentMethods={acceptedPaymentMethods}
              paymentMethodUpdated={paymentMethodUpdated}
              splitPaymentOptionUpdated={splitPaymentOptionUpdated}
              selectedPaymentMethod={paymentMethod}
              splitPayment={splitPaymentEnabled}
              enableUnderpayment={enableUnderpayment}
              disableSplitPayment={props.splitPaymentFeatureDisabled}
              wasUnderpaymentEnabled={processWasUnderpaymentEnabled}/>
            {
              paymentMethod === 'CARD' &&
              <Card amount={outstandingAmount} splitPaymentEnabled={splitPaymentEnabled} agencyCode={props.payment.agencyCode} surcharge={surchargeOverrides}/>
            }
            {
              paymentMethod === 'CASH' &&
              <Cash amountPayable={roundedAmountIfRoundingUp(outstandingAmount)} splitPaymentEnabled={splitPaymentEnabled}/>
            }
            {
              paymentMethod === 'CHEQUE' &&
              <Cheque amount={outstandingAmount} splitPaymentEnabled={splitPaymentEnabled}/>
            }
            {
              paymentMethod === 'MONEY_ORDER' &&
              <MoneyOrder amount={outstandingAmount} splitPaymentEnabled={splitPaymentEnabled}/>
            }
          </div>
          {splitPaymentEnabled && renderAddToTotalButton(paymentMethod)}
        </div>

        <div className='mr-auto w-1/3'>
          <ProcessPayment amount={props.payment.amount}
                          invoiceId={props.payment.agencyTransactionId}
                          processPaymentCallback={onProcessPaymentClicked}
                          cancelCallback={props.cancelPaymentCallback}
                          disabled={disabledForNonSplitPayment()}
                          overpaymentFeatureEnabled={props.overpaymentFeatureEnabled &&
                              acceptedPaymentMethods.includes('OVERPAYMENT')}
                          isUnderpayment={enableUnderpayment}
                          paymentMethod={paymentMethod}
                          splitPaymentEnabled={splitPaymentEnabled}
                          paymentReference={props.payment.paymentReference}
          />
        </div>
      </div>
    </div>
  )
}

PaymentForm.propTypes = {
  initialPaymentComponents: PropTypes.array.isRequired,
  payment: PropTypes.object.isRequired,
  overpaymentFeatureEnabled: PropTypes.bool.isRequired,
  splitPaymentFeatureDisabled: PropTypes.bool.isRequired,
  processPaymentCallback: PropTypes.func.isRequired,
  cancelPaymentCallback: PropTypes.func.isRequired,
  splitPaymentEnabled: PropTypes.bool,
  underpaymentFeatureEnabled: PropTypes.bool.isRequired,
}
