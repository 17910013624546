/* eslint-disable */
export class StringUtil {
  static formattedAmount = function (amount) {
    if (amount !== undefined) {
      const numberFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'})
      return numberFormat.format(amount)
    } else {
      return ''
    }
  }
}

