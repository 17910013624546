import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {StringUtil} from '../StringUtil'
import f10 from '../../../assets/images/keyboard-shortcuts/f10.svg'
import processingImage from '../../../assets/images/processing-summary/grey-spinner.svg'
import {Overpayment} from './Overpayment'
import {calculateTotalPaymentAmount} from '../calculateTotalPaymentAmount'
import {AppStateContext} from '../../App.state'
import OverpaymentOverrideModal from '../../processing-summary/overpayment/OverpaymentOverrideModal'
import axios from 'axios'
import roundedAmountIfRoundingUp from '../roundedAmountIfRoundingUp'
import UnderpaymentOverrideModal from '../../processing-summary/underpayment/UnderpaymentOverrideModal'

export function ProcessPayment(props) {
  const [processingPayment, setProcessingPayment] = useState(false)
  const processingPaymentRef = useRef(processingPayment)
  const [showModal, setShowModal] = useState(false)
  const [showUnderpaymentModal, setShowUnderpaymentModal] = useState(false)
  const [customer, setCustomer] = useState({
    customerId: '',
    customerReceiptType: '',
  })
  const {
    invoiceId,
    amount,
    cancelCallback,
    disabled,
    overpaymentFeatureEnabled,
    isUnderpayment,
    paymentMethod,
    splitPaymentEnabled,
    processPaymentCallback,
  } = props
  const F10_KEY_PROCESS_PAYMENT = 121

  const {appState} = useContext(AppStateContext)

  const getAmountTenderedForOverpayment = useCallback(() => {
    let amountTendered
    if (!splitPaymentEnabled) {
      switch (paymentMethod) {
        case 'MONEY_ORDER':
          amountTendered = appState.moneyOrderPayment.amount
          break
        case 'CHEQUE':
          amountTendered = appState.chequePayment.amount
          break
        default:
          amountTendered = 0
      }
    } else {
      amountTendered = calculateTotalPaymentAmount(appState.paymentComponents)
    }
    return amountTendered
  }, [paymentMethod, splitPaymentEnabled, appState])

  const getAmountTenderedForUnderpayment = useCallback(() => {
    return calculateTotalPaymentAmount(appState.paymentComponents)
  }, [appState])

  const validSinglePayment = useCallback(() => {
    let amountTendered, isValidSinglePayment
    if (!splitPaymentEnabled) {
      if (appState.paymentComponents && appState.paymentComponents.length === 1) {
        switch (appState.paymentComponents[0].componentType) {
          case 'MONEY_ORDER':
            amountTendered = appState.paymentComponents[0].amount
            break
          case 'CARD':
            amountTendered = appState.paymentComponents[0].amount
            break
          case 'CASH':
            amountTendered = appState.paymentComponents[0].amount
            break
          case 'CHEQUE':
            amountTendered = appState.paymentComponents[0].amount
            break
          default:
            amountTendered = 0
        }
        if (appState.paymentComponents[0].componentType === 'CASH') {
          const differenceAfterRoundingUp = amountTendered - roundedAmountIfRoundingUp(amount)
          isValidSinglePayment = (differenceAfterRoundingUp === 0)
        } else {
          const difference = amountTendered - amount
          isValidSinglePayment = (difference === 0)
        }
        return isValidSinglePayment
      } else if (paymentMethod) {
        switch (paymentMethod) {
          case 'MONEY_ORDER':
            amountTendered = appState.moneyOrderPayment.amount
            break
          case 'CARD':
            amountTendered = appState.cardPayment.amount
            break
          case 'CASH':
            amountTendered = appState.cashPayment.amount
            break
          case 'CHEQUE':
            amountTendered = appState.chequePayment.amount
            break
          default:
            amountTendered = 0
        }
        if (paymentMethod === 'CASH') {
          const differenceAfterRoundingUp = amountTendered - roundedAmountIfRoundingUp(amount)
          isValidSinglePayment = (differenceAfterRoundingUp === 0)
        } else {
          const difference = amountTendered - amount
          isValidSinglePayment = (difference === 0)
        }
        return isValidSinglePayment
      }
      return false
    }
  }, [splitPaymentEnabled, appState, paymentMethod, amount])

  const validSplitPaymentsBankablesAfterRounding = useCallback(() => {
    if (splitPaymentEnabled) {
      const bankablePaymentComponents = appState.paymentComponents.filter(paymentComponent =>
        (paymentComponent.componentType === 'CASH' || paymentComponent.componentType === 'CHEQUE'
          || paymentComponent.componentType === 'MONEY_ORDER') && paymentComponent.componentType !== 'CARD'
      )
      const cashComponent = appState.paymentComponents.filter(paymentComponent =>
        paymentComponent.componentType === 'CASH'
      )
      let paymentAmount
      if (cashComponent.length > 0 && !Number.isInteger(cashComponent[0].amount)) {
        paymentAmount = roundedAmountIfRoundingUp(amount)
      } else {
        paymentAmount = amount
      }
      if (bankablePaymentComponents.length > 0) {
        const differenceAfterRoundingUp = calculateTotalPaymentAmount(appState.paymentComponents) - paymentAmount
        return (differenceAfterRoundingUp === 0)
      }
    }
    return false
  }, [splitPaymentEnabled, amount, appState])

  const validSplitPaymentsWithAtleastOneCardWithZeroOrMoreBankables = useCallback(() => {
    if (splitPaymentEnabled) {
      const bankablePaymentComponents = appState.paymentComponents.filter(paymentComponent =>
        paymentComponent.componentType === 'CASH' || paymentComponent.componentType === 'CHEQUE'
        || paymentComponent.componentType === 'MONEY_ORDER'
      )
      const cardComponents = appState.paymentComponents.filter(paymentComponent =>
        paymentComponent.componentType === 'CARD'
      )
      if (bankablePaymentComponents.length >= 0 && cardComponents.length > 0) {
        const diffInSumOfComponentsAndRequestedAmount = calculateTotalPaymentAmount(appState.paymentComponents) - amount
        return (diffInSumOfComponentsAndRequestedAmount === 0)
      }
    }
    return false
  }, [splitPaymentEnabled, amount, appState])

  const isEligibleForOverPayment = useCallback(() => {
    let overpaidAmount
    if (overpaymentFeatureEnabled) {
      if (!splitPaymentEnabled) {
        switch (paymentMethod) {
          case 'MONEY_ORDER':
            overpaidAmount = appState.moneyOrderPayment.amount - amount
            break
          case 'CHEQUE':
            overpaidAmount = appState.chequePayment.amount - amount
            break
          default:
            overpaidAmount = 0
        }
        const isEligibleForSingleOverPayment = overpaidAmount > 0
        return isEligibleForSingleOverPayment
      } else {
        let isEligibleForSplitOverPayment
        const cardAndCardPaymentComponents = appState.paymentComponents.filter(paymentComponent =>
          paymentComponent.componentType === 'CASH' || paymentComponent.componentType === 'CARD'
        )
        if (cardAndCardPaymentComponents.length > 0) {
          isEligibleForSplitOverPayment = false
        } else {
          const overpaidAmount = calculateTotalPaymentAmount(appState.paymentComponents) - amount
          isEligibleForSplitOverPayment = overpaidAmount > 0
        }
        return isEligibleForSplitOverPayment
      }
    }
  }, [paymentMethod, overpaymentFeatureEnabled, splitPaymentEnabled, amount, appState])

  const validPaymentCriteria = validSinglePayment() || validSplitPaymentsBankablesAfterRounding()
    || validSplitPaymentsWithAtleastOneCardWithZeroOrMoreBankables() || isEligibleForOverPayment()
      || isUnderpayment

  const splitPaymentInitStage = splitPaymentEnabled && appState.paymentComponents.length === 0

  const splitPaymentOutstandingAmtCheck = splitPaymentEnabled
    && overpaymentFeatureEnabled && (calculateTotalPaymentAmount(appState.paymentComponents) > amount)

  const disablePayButton = disabled || processingPayment || !validPaymentCriteria

  const getCustomerDetails = useCallback(() => {
    const url = `${process.env.REACT_APP_CPP_OTC_BFF_URL || ''}/api/payments/${props.paymentReference}/customer`
    return axios.get(url)
  }, [props.paymentReference])

  const processPayment = useCallback(async () => {
    if (isUnderpayment) {
      setShowUnderpaymentModal(true)
    } else if (isEligibleForOverPayment()) {
      setProcessingPayment(true)
      const customerResponse = await getCustomerDetails()
        .catch(e => {
          console.log(e)
        })
      if (customerResponse && customerResponse.data) {
        setCustomer(customerResponse.data)
        setShowModal(true)
      }
    } else {
      if (!disablePayButton && !processingPaymentRef.current) {
        processingPaymentRef.current = true
        setProcessingPayment(true)
        const {processPaymentCallback} = props
        processPaymentCallback()
      }
    }
  }, [isUnderpayment, isEligibleForOverPayment, getCustomerDetails, disablePayButton, props])

  const handleKeys = useCallback((event) => {
    const {keyCode} = event
    if (keyCode === F10_KEY_PROCESS_PAYMENT) {
      processPayment()
    }
    return false

  }, [processPayment])

  const closeModal = useCallback(() => {
    setProcessingPayment(false)
    setShowModal(false)
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeys)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      document.removeEventListener('keydown', handleKeys)
    }
  }, [handleKeys, appState])

  function paymentComponentName(paymentComponent) {
    switch (paymentComponent.componentType) {
      case 'CASH':
        return 'Cash'
      case 'MONEY_ORDER':
        return `Money Order (${paymentComponent.orderNumber})`
      case 'CHEQUE':
        return `Cheque (${paymentComponent.chequeNumber})`
      case 'CARD':
        return 'Card'
      default:
        return paymentComponent.componentType
    }
  }

  function renderPaymentComponents() {
    return appState.paymentComponents.map((paymentComponent, index) => (
      <div className='flex flex-wrap mx-2 mt-2' key={paymentComponent.componentType + index}>
        <p className='w-1/2 text-left component-type'>{paymentComponentName(paymentComponent)}</p>
        <div
          className='w-1/2 amount font-bold text-right component-amount'>-{StringUtil.formattedAmount(paymentComponent.amount)}</div>
      </div>
    ))
  }

  function renderOutstandingAmount() {
    const outstandingAmount = amount - calculateTotalPaymentAmount(appState.paymentComponents)
    return (
      <div className='flex flex-wrap mx-2 mt-3'>
        <p className='w-1/2 font-bold text-left'>Amount outstanding</p>
        <div
          className='w-1/2 amount font-bold text-right outstanding-amount'>{StringUtil.formattedAmount(outstandingAmount)}</div>
      </div>
    )
  }

  return (
    <div id='process-payment-section'
         data-testid='process-payment-section'
         className='flex flex-col bg-snsw-gray-2 rounded-t-md'
         tabIndex='0'
         onKeyDown={processPayment}>
      <div className='invoice-id font-bold text-center bg-snsw-gray-4 p-2 w-full rounded-t-md'>Invoice
        ID: {invoiceId}</div>

      <div className='mx-4 mt-4'>
        <div className={`flex flex-wrap ${splitPaymentEnabled ? 'mx-2 mt-2' : ''}`}>
          <p className='w-1/2 text-left'>Amount</p>
          <div
            className='w-1/2 amount font-bold text-right requested-amount'>{StringUtil.formattedAmount(amount)}</div>
        </div>
        {!splitPaymentEnabled && <p className='text-sm'>(not including surcharge)</p>}
        {splitPaymentEnabled && renderPaymentComponents()}
        {splitPaymentEnabled && !isEligibleForOverPayment() && renderOutstandingAmount()}
      </div>

      {isEligibleForOverPayment() &&
      <Overpayment amount={amount} paymentMethod={paymentMethod} splitPaymentEnabled={splitPaymentEnabled}/>}
      {!validPaymentCriteria && !splitPaymentInitStage && splitPaymentOutstandingAmtCheck
      && !isEligibleForOverPayment() &&
      <div className='flex flex-wrap mx-2 mt-3'>
        <div className='overpayment-error font-bold w-full text-center text-primary'>
          Overpayment is not allowed, please cancel the payment
        </div>
      </div>
      }
      <button disabled={disablePayButton}
              onClick={processPayment}
              className={`pay-button mx-4 mt-6 rounded-md bg-primary p-2 font-bold text-white
                                    ${disablePayButton ? 'opacity-50 pointer-events-none select-none' : ''}`}>
        Process payment
        <img className='float-right' src={f10} alt='Press F10 to process payment'/>
        {processingPayment && (
          <img className='pay-button-spinner mr-2 h-6 w-6 float-right'
               src={processingImage}
               alt='Processing'
          />
        )}
      </button>
      <button
        className='cancel-button mx-4 my-6 rounded-md bg-snsw-gray-2 p-2 border-2 border-snsw-gray-5 font-bold text-snsw-blue-2'
        onClick={cancelCallback}>
        Cancel payment
      </button>
      <OverpaymentOverrideModal
        showModal={showModal}
        closeModal={closeModal}
        title='Overpayment refund'
        invoiceId={invoiceId}
        amountRequested={amount}
        amountTendered={getAmountTenderedForOverpayment()}
        approveCallback={processPaymentCallback}
        customerId={customer.customerId}
        paymentReference={props.paymentReference}
        customerReceiptType={customer.customerReceiptType}
      />
      <UnderpaymentOverrideModal
          showModal={showUnderpaymentModal}
          closeModal={() => setShowUnderpaymentModal(false)}
          title='Underpayment'
          invoiceId={invoiceId}
          amountRequested={amount}
          approveCallback={processPaymentCallback}
          paymentReference={props.paymentReference}
          amountTendered={getAmountTenderedForUnderpayment()}
      />
    </div>
  )
}

ProcessPayment.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  processPaymentCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  overpaymentFeatureEnabled: PropTypes.bool.isRequired,
  isUnderpayment: PropTypes.bool,
  paymentMethod: PropTypes.string.isRequired,
  splitPaymentEnabled: PropTypes.bool,
  paymentReference: PropTypes.string.isRequired,
}
