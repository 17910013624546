import React from 'react'
import PropTypes from 'prop-types'
import {PaymentAmount} from './PaymentAmount'

PaymentDetails.propTypes = {
  amount: PropTypes.number,
}

export function PaymentDetails(props) {
  return (
    <div data-testid='payment-details-section' className='payment-details-section '>
      <div className='border-b border-snsw-gray-6'>
        <div className='text-2xl font-bold pb-3'>Payment Details</div>
      </div>
      <PaymentAmount label='Full amount' amount={props.amount} fieldDisabled={true}/>
    </div>
  )
}


