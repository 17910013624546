import * as yup from 'yup'
import {
  CHANGE_PAYMENT_METHOD, SET_CARD_AMOUNT,
} from '../Actions'

yup.setLocale({
  mixed: {
    notType: ({ type}) => {
      return `Must be a ${type}`
    },
  },
})

export const CardStateSchema = yup.object().shape({
  amount: yup.number()
    .positive('Amount should be greater than zero')
    .required('Amount is mandatory'),
})

export const cardStateReducer = (cardState = undefined, action) => {
  switch (action.type) {
    case SET_CARD_AMOUNT:
      return {
        ...cardState,
        amount: action.value,
      }
    case CHANGE_PAYMENT_METHOD:
      return action.paymentMethod === 'CARD' ? blankCardState : undefined
    default:
      return cardState
  }
}

export const blankCardState = {
  amount: undefined,
}
