import React from 'react'
import PropTypes from 'prop-types'

PaymentMessage.propTypes = {
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  message: PropTypes.string,
}

export function PaymentMessage(props) {
  return (
    <div className="w-2/3 h-full self-center">
      <div className="bg-snsw-gray-7 lg:border-l-4 lg:border-snsw-gray-11 p-4 mt-8">
        <div className="flex flex-row">
          <img className="h-6 ml-4 w-6" src={props.image} alt={props.imageAlt}/>
          <h4 className="ml-6">{props.message}</h4>
        </div>
      </div>
    </div>
  )
}
