import React from 'react'
import PropTypes from 'prop-types'

PaymentStatusMessage.propTypes = {
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  message: PropTypes.string,
  className: PropTypes.string,
}

export function PaymentStatusMessage(props) {
  return (
    <div className={`${props.className} flex flex-row`}>
      <img className="mt-6 h-6 ml-4 w-6" src={props.image} alt={props.imageAlt}/>
      <h4 className="mt-6 ml-2">{props.message}</h4>
    </div>
  )
}


