import {PaymentStatusMessage} from '../PaymentStatusMessage'
import greenTick from '../../../assets/images/processing-summary/green-tick.svg'
import PropTypes from 'prop-types'
import React from 'react'

export function MoneyOrderStatusMessage(props) {
  return (
    <div>
      <PaymentStatusMessage
        className={props.className}
        image={greenTick}
        message={props.message}
      />
    </div>
  )
}

MoneyOrderStatusMessage.propTypes = {
  className: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

export function CollectedMoneyOrderStatusMessage(props) {
  const message = `Money order payment of $${props.amount} collected - MO number ${props.moneyOrderNumber}`
  return (
    <MoneyOrderStatusMessage className='money-order-collected' message={message} />
  )
}

CollectedMoneyOrderStatusMessage.propTypes = {
  amount: PropTypes.number.isRequired,
  moneyOrderNumber: PropTypes.string.isRequired,
}

export function VoidedMoneyOrderStatusMessage(props) {
  const message = `Payment voided, please return MO number ${props.moneyOrderNumber} to the customer`
  return (
    <MoneyOrderStatusMessage className='money-order-voided' message={message} />
  )
}

VoidedMoneyOrderStatusMessage.propTypes = {
  moneyOrderNumber: PropTypes.string.isRequired,
}
