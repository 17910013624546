import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {AppStateContext} from '../../../App.state'
import {CHANGE_PAYMENT_METHOD, RESET_PAYMENT_PROCESS} from './Actions'
import Switch from 'react-switch'

export function PaymentOptions({acceptedPaymentMethods, paymentMethodUpdated, splitPaymentOptionUpdated, selectedPaymentMethod, splitPayment, enableUnderpayment, disableSplitPayment, wasUnderpaymentEnabled}) {
  const {dispatch} = useContext(AppStateContext)
  const onSplitPaymentToggled = nextChecked => {
    dispatch({type: RESET_PAYMENT_PROCESS})
    splitPaymentOptionUpdated(nextChecked)
    wasUnderpaymentEnabled(false)
  }

  function onPaymentMethodChosen(methodName) {
    if (methodName !== selectedPaymentMethod) {
      dispatch({type: CHANGE_PAYMENT_METHOD, paymentMethod: methodName})
      paymentMethodUpdated(methodName)
    }
  }

  function isChecked() {
    return splitPayment || wasUnderpaymentEnabled()
  }

  function isSplitPaymentDisabled() {
    return enableUnderpayment || disableSplitPayment
  }

  return (
    <div>
      <div className='border-b border-snsw-gray-6'>
        <div className='text-2xl font-bold pb-3'>Select payment type</div>
      </div>
      <div className="m-8 relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
        <Switch
          onChange={onSplitPaymentToggled}
          checked={isChecked()}
          uncheckedIcon={false}
          checkedIcon={false}
          className="split-payment-toggle"
          disabled={isSplitPaymentDisabled()}
        />
      </div>
      <label htmlFor="toggle" className="ml-4 w-1/2 self-center text-lg">Split Payment</label>
      <div className='flex flex-row mt-4 payment-option-section'>
        {acceptedPaymentMethods.includes('CARD') &&
            <button id="card-option" data-testid="card-option"
                 className={selectedPaymentMethod === 'CARD'? 'payment-option-selected' : 'payment-option'}
                 onClick={() => {
                   onPaymentMethodChosen('CARD')
                 }}>
              <span className='mr-2'>Card</span>
            </button>
        }
        {acceptedPaymentMethods.includes('CASH') &&
              <button id="cash-option" data-testid="cash-option"
                      className={selectedPaymentMethod === 'CASH' ? 'payment-option-selected' : 'payment-option'}
                      onClick={() => {
                        onPaymentMethodChosen('CASH')
                      }}>
                <span className='mr-2'>Cash</span>
              </button>
        }
        {acceptedPaymentMethods.includes('CHEQUE') &&
              <button id="cheque-option" data-testid="cheque-option"
                      className={selectedPaymentMethod === 'CHEQUE' ? 'payment-option-selected' : 'payment-option'}
                      onClick={() => {
                        onPaymentMethodChosen('CHEQUE')
                      }}>
                <span className='mr-2'>Cheque</span>
              </button>
        }
        {acceptedPaymentMethods.includes('MONEY_ORDER') &&
              <button id="money-order-option" data-testid="money-order-option"
                      className={selectedPaymentMethod === 'MONEY_ORDER' ? 'payment-option-selected' : 'payment-option'}
                      onClick={() => {
                        onPaymentMethodChosen('MONEY_ORDER')
                      }}>
                <span className='mr-2 whitespace-no-wrap'>Money Order</span>
              </button>
        }

        <div className='h-16'></div>
      </div>
    </div>
  )
}

PaymentOptions.defaultProps = {
  selectedPaymentMethod: 'CARD',
}

PaymentOptions.propTypes = {
  acceptedPaymentMethods: PropTypes.array.isRequired,
  paymentMethodUpdated: PropTypes.func,
  splitPaymentOptionUpdated: PropTypes.func,
  selectedPaymentMethod: PropTypes.string,
  splitPayment: PropTypes.bool,
  enableUnderpayment: PropTypes.bool,
  disableSplitPayment: PropTypes.bool,
  wasUnderpaymentEnabled: PropTypes.func,
}
