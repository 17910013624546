import React, {useCallback, useContext} from 'react'
import PropTypes from 'prop-types'
import {StringUtil} from '../StringUtil'
import {AppStateContext} from '../../App.state'
import {calculateTotalPaymentAmount} from '../calculateTotalPaymentAmount'

export function Overpayment({amount, paymentMethod, splitPaymentEnabled}) {
  const {appState} = useContext(AppStateContext)

  const calculateOverpayment = useCallback(() => {
    if (!splitPaymentEnabled) {
      switch (paymentMethod) {
        case 'MONEY_ORDER':
          return appState.moneyOrderPayment.amount - amount
        case 'CHEQUE':
          return appState.chequePayment.amount - amount
        default:
          return 0
      }
    } else {
      const overpaidAmount = calculateTotalPaymentAmount(appState.paymentComponents) - amount
      return overpaidAmount
    }
  }, [paymentMethod, splitPaymentEnabled, amount, appState])

  const overpaidAmount = calculateOverpayment()

  if (overpaidAmount <= 0) return <div/>

  return (
    <div className='mx-4 mt-4'>
      <div className='flex flex-wrap'>
        <p className='w-1/2 text-left'>Overpayment</p>
        <div
          className='w-1/2 overpayment font-bold text-right'>{StringUtil.formattedAmount(overpaidAmount)}</div>
      </div>
    </div>
  )
}

Overpayment.prototype.propTypes = {
  amount: PropTypes.number.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  splitPaymentEnabled: PropTypes.bool.isRequired,
}
