import axios from 'axios'

export const voidOverridePayment = async ({paymentReference, reason, supervisorId, supervisorCode, overrideReason}) => {
  try {
    return await axios.post(`/api/payments/${paymentReference}/void-override`,
      {
        reason,
        supervisorId,
        supervisorCode,
        overrideReason,
      })
  } catch (error) {
    return error.response
  }
}

export const getPaymentDetails = async (paymentReference) => {
  try {
    const {data} = await axios.get(`/api/payments/${paymentReference}`)
    return data
  } catch (error) {
    return error.response
  }
}