import axios from 'axios'

export const STATUS_POLL_INTERVAL_MILLISECONDS = 1000

export default class PollingStatusProvider {
  paymentOrRefundReference
  pollInterval
  onStatusUpdateCallback
  latestStatus
  statusUrl

  constructor(paymentOrRefundReference, transactionType = 'payment') {
    this.paymentOrRefundReference = paymentOrRefundReference
    if(transactionType === 'payment') {
      this.statusUrl = `${process.env.REACT_APP_CPP_OTC_BFF_URL || ''}/api/payments/${this.paymentOrRefundReference}/status`
    } else if(transactionType === 'refund') {
      this.statusUrl = `${process.env.REACT_APP_CPP_OTC_BFF_URL || ''}/api/refunds/${this.paymentOrRefundReference}/status`
    }
    this._poll()
  }

  onStatusUpdate(callback) {
    this.onStatusUpdateCallback = callback
    this.latestStatus && callback(this.latestStatus)
  }

  onCompletion(completionCallback) {
    this.completionCallback = completionCallback
    this._handleCompletionUrl()
  }

  stop() {
    clearInterval(this.pollInterval)
  }

  restart() {
    this.stop()
    this._poll()
  }

  _poll() {
    this._fetchLatestStatus()
    this.pollInterval = setInterval(this._fetchLatestStatus.bind(this), STATUS_POLL_INTERVAL_MILLISECONDS)
  }

  _fetchLatestStatus() {
    axios.get(this.statusUrl).then(response => {
      if (JSON.stringify(response.data) === JSON.stringify(this.latestStatus)) {
        return
      } else {
        this.latestStatus = response.data
      }

      this._handleNewStatus(response.data)
    })
  }

  _handleNewStatus(newStatus) {
    if (newStatus.final) {
      this.stop()
    }

    this._handleCompletionUrl()

    this.onStatusUpdateCallback && this.onStatusUpdateCallback({...newStatus})
  }

  _handleCompletionUrl() {
    if (this.latestStatus && this.latestStatus.completionUrl && this.latestStatus.completionUrl.trim().length > 0) {
      this.completionCallback && this.completionCallback(this.latestStatus.completionUrl)
    }
  }
}
