import React from 'react'
import PropTypes from 'prop-types'
import { PaymentStatusMessage } from '../PaymentStatusMessage'
import greenTick from '../../../assets/images/processing-summary/green-tick.svg'

export function CashStatusMessage(props) {
  return <PaymentStatusMessage
    className={props.className}
    image={greenTick}
    message={props.message}
  />
}
CashStatusMessage.propTypes = {
  message: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
}

export function CollectedCashStatusMessage(props) {
  const message = `Cash payment of $${props.roundedAmount} collected`
  return <CashStatusMessage className="cash-collected" message={message}/>
}

CollectedCashStatusMessage.propTypes = {
  roundedAmount: PropTypes.number.isRequired,
}

export function VoidedCashStatusMessage(props) {
  const message = `Payment voided, please return cash payment of $${props.roundedAmount} to the customer`
  return <CashStatusMessage className="cash-voided" message={message} />
}

VoidedCashStatusMessage.propTypes = {
  roundedAmount: PropTypes.number.isRequired,
}
