import React from 'react'
import ReactDOM from 'react-dom'
import './output.css'
import * as serviceWorker from './serviceWorker'
import queryString from 'query-string'
import StatefulApp from './app/StatefulApp'

const paymentReferenceParam = queryString.parse(window.location.search).paymentReference
const refundReferenceParam = queryString.parse(window.location.search).refundReference
ReactDOM.render(<StatefulApp
  paymentReference={paymentReferenceParam} refundReference={refundReferenceParam}
/>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
