import {CashStateSchema} from './collect-payment/options/cash/Cash.state'
import {ChequeStateSchema} from './collect-payment/options/cheque/Cheque.state'
import {MoneyOrderStateSchema} from './collect-payment/options/moneyOrder/MoneyOrder.state'
import {CardStateSchema} from './collect-payment/options/card/Card.state'

export function PaymentFormStateValidator(paymentMethod) {
  const VALID_RESULT = {isValid: true}
  const schemas = {
    CASH: CashStateSchema,
    CHEQUE: ChequeStateSchema,
    MONEY_ORDER: MoneyOrderStateSchema,
    CARD: CardStateSchema,
  }

  const schemaKeys = {
    CASH: 'cashPayment',
    CHEQUE: 'chequePayment',
    MONEY_ORDER: 'moneyOrderPayment',
    CARD: 'cardPayment',
  }

  function validateGlobalState(globalState) {
    if (!globalState) return {...VALID_RESULT}
    let schemaKey = undefined
    if (Object.prototype.hasOwnProperty.call(schemaKeys, paymentMethod)){
      schemaKey = schemaKeys[paymentMethod]
      return validateState(globalState[schemaKey])
    } else {
      return {
        isValid: false,
        errorMessage:'Invalid property : '+paymentMethod+' in schemaKeys object',
      }
    }
  }

  function validateState(state) {
    try {
      let schema = undefined
      if (Object.prototype.hasOwnProperty.call(schemas, paymentMethod)){
        schema = schemas[paymentMethod]
      }
      if (schema) {
        schema.validateSync(
          state,
          {strict: true}
        )
      }
      return {...VALID_RESULT}
    } catch (error) {
      return {
        isValid: false,
        errorMessage: error.message,
      }
    }
  }

  function validateField(fieldName, state) {
    try {
      let schema = undefined
      if (Object.prototype.hasOwnProperty.call(schemas, paymentMethod)){
        schema = schemas[paymentMethod]
      }
      if (schema) {
        schema.validateSyncAt(
          fieldName,
          state,
          {strict: true}
        )
      }
      return {...VALID_RESULT}
    } catch (error) {
      return {
        isValid: false,
        errorMessage: error.message,
      }
    }
  }

  return {
    validateGlobalState: validateGlobalState,
    validateState: validateState,
    validateField: validateField,
  }
}
