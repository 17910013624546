import PropTypes from 'prop-types'
import {PaymentAmount, ValidatablePaymentAmount} from '../../PaymentAmount'
import React, {useState, useEffect, useContext} from 'react'
import {AppStateContext} from '../../../../App.state'
import {SET_CASH_AMOUNT, SET_CASH_TENDERED_AMOUNT} from '../Actions'
import {PaymentFormStateValidator} from '../../../PaymentFormStateValidator'

export function Cash(props) {
  const {appState, dispatch} = useContext(AppStateContext)
  const [change, setChange] = useState(0.0)
  const paymentFormStateValidator = PaymentFormStateValidator('CASH')

  useEffect(() => {
    amountPayableInputChanged(props.amountPayable)
    tenderedAmountInputChanged(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function amountPayableInputChanged(newAmountPayable) {
    setChange(0)
    dispatch({type: SET_CASH_AMOUNT, value: newAmountPayable})
    dispatch({type: SET_CASH_TENDERED_AMOUNT, value: newAmountPayable})
  }

  function tenderedAmountInputChanged(newTenderedAmount) {
    const amountPayable = (appState.cashPayment && appState.cashPayment.amount) || 0
    if (newTenderedAmount > 0) {
      const newChange = newTenderedAmount - amountPayable
      setChange(Number(newChange.toFixed(2)))
    } else {
      setChange(Number(0))
    }
    dispatch({type: SET_CASH_TENDERED_AMOUNT, value: newTenderedAmount})
  }

  const amountPayableErrorMessage = paymentFormStateValidator
    .validateField('amount', appState.cashPayment).errorMessage
  const tenderedAmountErrorMessage = paymentFormStateValidator
    .validateField('tenderedAmount', appState.cashPayment).errorMessage

  return (
    <div id='cash-section' data-testid='cash-section' className='border-4 border-snsw-gray-7 m-2 mt-6'>
      {
        !props.splitPaymentEnabled &&
            <PaymentAmount label='Amount payable'
                           id='cash-amount'
                           data-testid='cash-amount-disabled'
                           amount={props.amountPayable}
                           fieldDisabled={true}/>
      }
      {
        props.splitPaymentEnabled &&
            <ValidatablePaymentAmount
                id='cash-amount'
                label='Amount payable'
                amount={(appState.cashPayment && appState.cashPayment.amount) || 0}
                amountChange={amountPayableInputChanged}
                errorId='cash-amount-validation-error'
                errorMessage={props.amountPayable > 0 ? amountPayableErrorMessage : ''}
                roundingDisabled={false}
                fieldDisabled={props.amountPayable <= 0}
            />
      }
      <ValidatablePaymentAmount
            id='cash-tendered-amount'
            label='Tendered amount'
            amount={(appState.cashPayment && appState.cashPayment.tenderedAmount) || 0}
            amountChange={tenderedAmountInputChanged}
            errorId='cash-tendered-amount-validation-error'
            errorMessage={tenderedAmountErrorMessage}
            roundingDisabled={false}
            fieldDisabled={props.amountPayable <= 0}
      />
      <PaymentAmount id='change-amount' label='Change' amount={change} fieldDisabled={true}/>
    </div>
  )
}

Cash.propTypes = {
  amountPayable: PropTypes.number.isRequired,
  splitPaymentEnabled: PropTypes.bool.isRequired,
}
