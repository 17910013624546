import React, {useEffect, useState} from 'react'
import {ReasonForUnpayField} from './ReasonForUnpayField'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import {getPaymentDetails, voidOverridePayment} from './VoidOverrideModalApi'
import OverrideModal from '../../OverrideModal'

const USER_UNPAY_REQUESTED = 'USER_UNPAY_REQUESTED'

const OverrideModalSchema = yup.object().shape({
  reasonForUnpay: yup.string()
    .required('This field is required'),
})
const VoidOverrideModal = ({
  showModal,
  status,
  paymentReference,
  closeModal,
}) => {

  const [state, setState] = useState({
    reasonForUnpay: {value: '', error: ''},
    counterReference: '',
    amount: null,
    agencyTransactionId: '',
  })

  const validateFieldInner = (fieldName, fieldValue) => {
    const VALID_RESULT = {isValid: true}
    try {
      OverrideModalSchema.validateSyncAt(fieldName, {[fieldName]: fieldValue}, {strict: true})
      return VALID_RESULT
    } catch (error) {
      return {
        isValid: false,
        errorMessage: error.message,
      }
    }
  }

  const handleApproveClick = async (parentOverrideDetails, handleOverridePaymentApiCall) => {
    const overrideDetails = {
      ...parentOverrideDetails,
      paymentReference,
      reason: USER_UNPAY_REQUESTED,
      overrideReason: state.reasonForUnpay.value,
    }

    handleOverridePaymentApiCall(overrideDetails, voidOverridePayment)
  }

  const validateField = (fieldName) => {
    const validation = validateFieldInner(fieldName, state[fieldName].value)
    if (!validation.isValid) {
      setState({...state, [fieldName]: {...state[fieldName], error: validation.errorMessage}})
    } else {
      setState({...state, [fieldName]: {...state[fieldName], error: ''}})
    }
  }

  const reasonForUnpayValidate = () => validateField('reasonForUnpay')

  const approveEnabled = state.reasonForUnpay.value !== ''

  useEffect(() => {
    if (showModal) {
      setState(
        {
          reasonForUnpay: {value: '', error: ''},
          counterReference: '',
          amount: null,
          agencyTransactionId: '',
        }
      )

      getPaymentDetails(paymentReference).then((data) => {
        setState(s => ({
          ...s,
          counterReference: data.payment.counterReference,
          amount: data.payment.amount,
          agencyTransactionId: data.payment.agencyTransactionId,
        }))
      }).catch((error) => {
        console.log(`Error ${error} while invoking getPaymentDetails`)
      })
    }
  }, [showModal, paymentReference])

  if (showModal) {
    return (
      <div>
        <OverrideModal
          showModal={showModal}
          closeModal={closeModal}
          approve={handleApproveClick}
          approveEnabled={approveEnabled}
          title='Approve payment removal'
        >
          <div className='grid grid-cols-12 gap-2 max-w-lg'>
            <div className='col-span-5 font-bold'>DRIVES Transaction ID</div>
            <div className='col-span-7'>{state.agencyTransactionId}</div>
            <div className='col-span-5 font-bold'>Amount</div>
            <div className='col-span-7'>{state.amount}</div>
            <div className='col-span-5 font-bold'>Location ID/IP Address</div>
            <div className='col-span-7'>{state.counterReference}</div>
            <div className='col-span-5 font-bold'>Status</div>
            <div className='col-span-7'>{status}</div>
          </div>

          <ReasonForUnpayField
            label='Reason for payment removal'
            inputId='reason-for-unpay'
            topDivClass='flex flex-col'
            subDivClass='w-full mt-4'
            inputClass='p-2 border-2 rounded block text-sm w-full'
            labelClass='font-bold'
            textChangedCallback={value => {
              setState({...state, reasonForUnpay: {value, error: ''}})}}
            initValue={state.reasonForUnpay.value}
            errorMessage={state.reasonForUnpay.error}
            validationCallback={reasonForUnpayValidate}
            placeholder='Please enter reason'
          />
        </OverrideModal>
      </div>
    )
  }
  return null
}

VoidOverrideModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  status: PropTypes.string,
  paymentReference: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
}


export default VoidOverrideModal
