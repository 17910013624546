import React, {useContext, useEffect, useState} from 'react'
import {PaymentAmount, ValidatablePaymentAmount} from '../../PaymentAmount'
import PropTypes from 'prop-types'
import {AppStateContext} from '../../../../App.state'
import {
  SET_MONEY_ORDER_AMOUNT,
  SET_MONEY_ORDER_ISSUED_DATE,
  SET_MONEY_ORDER_NUMBER,
  SET_MONEY_ORDER_UNDERPAYMENT,
} from '../Actions'
import {ValidatableField} from '../ValidatableField'
import {PaymentFormStateValidator} from '../../../PaymentFormStateValidator'

const validator = PaymentFormStateValidator('MONEY_ORDER')
export default function MoneyOrder(props) {
  const {appState, dispatch} = useContext(AppStateContext)
  const [moneyOrderInitiallyLoaded, setMoneyOrderInitiallyLoaded] = useState(false)
  useEffect(() => {
    setMoneyOrderInitiallyLoaded(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {moneyOrderPayment: {orderNumber, issuedDate}} = appState

  const orderNumberErrorMessage = validator.validateField('orderNumber', appState.moneyOrderPayment).errorMessage
  const issuedDateErrorMessage = validator.validateField('issuedDate', appState.moneyOrderPayment).errorMessage
  const underpaymentErrorMessage = validator.validateField('underpayment', appState.moneyOrderPayment).errorMessage
  const amountErrorMessage = validator.validateField('amount', appState.moneyOrderPayment).errorMessage

  function moneyOrderAmountInputChanged(newMoneyOrderAmount) {
    setMoneyOrderInitiallyLoaded(false)
    dispatch({type: SET_MONEY_ORDER_AMOUNT, value: newMoneyOrderAmount})
    if (props.splitPaymentEnabled) {
      dispatch({type: SET_MONEY_ORDER_UNDERPAYMENT, value: false})
    } else {
      const isUnderpayment = newMoneyOrderAmount < props.amount
      dispatch({type: SET_MONEY_ORDER_UNDERPAYMENT, value: isUnderpayment})
    }
  }

  return (
    <div id='money-order-section' data-testid='money-order-section' className='border-4 border-snsw-gray-7 m-2 mt-6'>
      {!props.splitPaymentEnabled && <PaymentAmount id='money-order-amount-to-be-charged' label='Amount to be charged' amount={props.amount}
                      fieldDisabled={true}/>}
      <ValidatablePaymentAmount
        id='money-order-payment-amount'
        label='Money Order Amount'
        amount={undefined}
        amountChange={moneyOrderAmountInputChanged}
        errorId='money-order-amount-validation-error'
        errorMessage={(!moneyOrderInitiallyLoaded && props.splitPaymentEnabled) ?
          (props.amount > 0 ? amountErrorMessage : '') :
          underpaymentErrorMessage}
        fieldDisabled={props.amount <= 0}
      />
      <ValidatableField
        label='Money Order Number'
        inputId='money-order-number'
        actionType={SET_MONEY_ORDER_NUMBER}
        errorMessage={orderNumber !== undefined && orderNumberErrorMessage}
        fieldDisabled={props.amount <= 0}
      />
      <ValidatableField
        label='Institution'
        inputId='money-order-institution'
        placeholder='Australia Post'
        fieldDisabled={true}
      />
      <ValidatableField
        label='Issued Date'
        inputId='money-order-issued-date'
        actionType={SET_MONEY_ORDER_ISSUED_DATE}
        placeholder='YYYY-MM-DD'
        errorMessage={issuedDate !== undefined && issuedDateErrorMessage}
        fieldDisabled={props.amount <= 0}
      />
    </div>
  )
}

MoneyOrder.propTypes = {
  amount: PropTypes.number.isRequired,
  splitPaymentEnabled: PropTypes.bool.isRequired,
}
